import React, { useCallback, useState } from 'react';
import Layout from '../Layout/Layout';
import { Box, Button, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PartnerResourcesModal from './PartnerResourcesModal';

const PartnerResources = () => {
  const [showResourceModal, setShowResourceModal] = useState(false);

  const handleCloseResourceModal = useCallback(() => {
    setShowResourceModal(false);
  }, []);

  return (
        <Layout pageTitle="Partner Resources">
            <Box component="section" sx={{ maxWidth: '1000px', px: 4, py: 2 }}>
                <Typography variant="h5" sx={{ my: 2, fontWeight: 500 }}>
                    Partner Resource Center
                </Typography>
            </Box>
            <Box
                component="section"
                sx={{
                  maxWidth: '1000px',
                  padding: 0,
                  display: 'flex',
                  flexWrap: 'wrap',
                  borderRadius: '4px',
                  alignItems: 'stretch',
                  border: '1px solid #e1e3e5',
                }}
            >
                <Box
                    sx={{
                      flex: 1,
                      padding: 4,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                >
                    <Typography variant="body2" sx={{ my: 1 }}>
                        Explore a wide range of documents, templates, and policies. Find resources that can help you navigate various aspects of your membership.
                    </Typography>
                    <Button
                        variant="outlined"
                        sx={{ my: 2, paddingX: 2, paddingY: 1, width: '100%', maxWidth: '300px' }}
                        onClick={() => setShowResourceModal(true)}
                    >
                        Explore Partner Resource Center
                    </Button>
                </Box>
                <Box
                    sx={{
                      flex: 1,
                      ml: 1,
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/tpn_security_lock_rounded.png)`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      display: 'flex',
                      alignItems: 'stretch',
                      justifyContent: 'center',
                    }}
                />
            </Box>
            <Accordion sx={{ maxWidth: '1000px', padding: 2, my: 4, border: '1px solid #e1e3e5', backgroundColor: 'inherit' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ backgroundColor: 'inherit' }}
                >
                    <Typography variant="h5">Getting Started with Partner Resources</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                        Watch the explainer video below to understand how to navigate and make the most of the Partner Resource Center.
                    </Typography>
                    <Box
                        component="iframe"
                        sx={{ width: '100%', height: '500px', border: 'none' }}
                        src="https://www.youtube.com/embed/nH0cR4ihBYo"
                        title="Getting Started with Member Resources"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </AccordionDetails>
            </Accordion>
            <PartnerResourcesModal showResourceModal={showResourceModal} handleCloseResourceModal={handleCloseResourceModal}/>
        </Layout>
  );
};

export default PartnerResources;
