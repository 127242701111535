import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
//Modal
import { StandardDialogActions } from '../Modals/StandardDialog';
import StandardDialog from '../Modals/StandardDialog';
// Icons
import DownloadIcon from '@mui/icons-material/Download';
import ReviewsIcon from '@mui/icons-material/Reviews';
import SearchIcon from '@mui/icons-material/Search';
// Material-UI
import { Box, Button, Card, CardContent, CardMedia, Grid, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';

import PartnerResource from './PartnerResource';
import { PartnerResource as PartnerResourceInterface } from '../../interfaces/partnerResources.interface';
import { getHelpURL } from '../../services/user';
import { useAppSelector } from '../../app/hooks';

const sections: { [key: string]: PartnerResourceInterface[] } = {
  'Policies': [
    {
      id: 1,
      title: 'Business Continuity Plan',
      description: 'This plan helps businesses remain operational during disruptions. It includes strategies to allocate resources and maintain critical functions. Prepare for risks, minimize downtime, and ensure essential services continue. Build resilience to handle crises like natural disasters or cyberattacks effectively.',
      shortDescription: 'Strategies for business resilience during disruptions.',
      datePublished: '2023-09-12',
      documentType: 'policy',
    },
    {
      id: 2,
      title: 'Disaster Recovery Plan',
      description: 'This guide provides steps to restore critical operations after disasters. It emphasizes safeguarding IT systems and resuming business functions efficiently. Teams can follow clear procedures to recover quickly and minimize financial and operational strain.',
      shortDescription: 'Guide to recovering from disasters effectively.',
      datePublished: '2023-09-20',
      documentType: 'policy',
    },
    {
      id: 3,
      title: 'Incident Response',
      description: 'A framework for identifying and mitigating security incidents. It includes step-by-step guidance to reduce risks and recover operations. Use this plan to respond effectively to data breaches and cyberattacks, minimizing damage and future risks.',
      shortDescription: 'Effective procedures for managing incidents.',
      datePublished: '2023-08-25',
      documentType: 'policy',
    },
    {
      id: 4,
      title: 'Risk Management',
      description: 'Learn to identify, assess, and address organizational risks. This guide covers techniques for mitigating potential issues and maintaining stability. Create a structured framework to prioritize responses and safeguard your resources.',
      shortDescription: 'Resource for analyzing and mitigating risks.',
      datePublished: '2023-10-01',
      documentType: 'policy',
    },
    {
      id: 5,
      title: 'Training & Awareness',
      description: 'Develop training programs to educate employees on best practices. Build a culture of compliance and ensure teams are equipped to handle challenges. This guide emphasizes the importance of ongoing education and reinforcement.',
      shortDescription: 'Programs for training and raising awareness.',
      datePublished: '2023-07-15',
      documentType: 'policy',
    },
  ],
  'Templates': [
    {
      id: 6,
      title: 'Template 1',
      description: 'This template helps standardize processes and improve efficiency. Use it to create consistent reports, plans, or projects. It provides a structured framework to reduce errors and streamline workflows.',
      shortDescription: 'A useful template to simplify processes.',
      datePublished: '2023-07-25',
      documentType: 'template',
    },
    {
      id: 7,
      title: 'Template 2',
      description: 'Designed to enhance workflow and organization. This customizable template ensures clarity and consistency across projects. Use it to improve collaboration and maintain professional standards.',
      shortDescription: 'A template for streamlined workflows.',
      datePublished: '2023-08-10',
      documentType: 'template',
    },
  ],
};
  

const gradientColors = [
  { start: '#2C3E50', end: '#4CA1AF' },  // Dark Blue to Teal
  { start: '#34495E', end: '#2C3E50' },  // Slate Gray to Charcoal Blue
  { start: '#1F1C2C', end: '#928DAB' },  // Dark Violet to Soft Gray
  { start: '#2E4053', end: '#4B79A1' },  // Dark Slate to Steel Blue
  { start: '#0F2027', end: '#203A43' },  // Dark Teal to Deep Ocean Blue
  { start: '#3A6073', end: '#16222A' },  // Slate Blue to Dark Teal
];

export default function PartnerResourcesModal({ showResourceModal, handleCloseResourceModal }: { showResourceModal: boolean, handleCloseResourceModal: () => void }) {
  const [expandedSection, setExpandedSection] = useState<string | null>(null);
  const [selectedDocument, setSelectedDocument] = useState<any | null>(null);
  const { resourceSection, documentId } = useParams();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const { user } = useAppSelector(state => state.user);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedDocument !== null) setSelectedDocument(null);
    setSearchQuery(event.target.value.toLowerCase());
  };

  // Filter sections based on the search query
  const filteredSections = expandedSection
    ? sections[expandedSection].filter((item) =>
      item.title.toLowerCase().includes(searchQuery) || item.description.toLowerCase().includes(searchQuery),
    )
    : [];

  const handleSectionClick = (section: string) => {
    setExpandedSection(section);
    navigate(`/partner-resources/${section}`);
  };

  const handleCloseEduCenter = () => {
    handleCloseResourceModal();
    setExpandedSection('Policies');
    setSelectedDocument(null);
    setSearchQuery('');
  };

  useEffect(() => {
    if (documentId && expandedSection) {
      const selectedDoc = sections[expandedSection].find((doc) => doc.id === parseInt(documentId));
      if (selectedDoc) setSelectedDocument(selectedDoc);
    }
  }, [documentId, expandedSection]);

  useEffect(() => {
    if (resourceSection) {
      setExpandedSection(resourceSection);
    } else {
      setExpandedSection('Policies');
    }
  }, [resourceSection]);

  const handleCardClick = (document: any) => {
    navigate(`/partner-resources/${expandedSection}/${document.id}`);
  };

  const handleBackClick = () => {
    if (documentId) {
      navigate(`/partner-resources/${expandedSection}`);
    } else {
      navigate('/partner-resources');
    }
  };

  return (
        <StandardDialog  maxWidth="xl" title="Partner Resource Center" isOpen={showResourceModal} handleClose={handleCloseEduCenter}>
        <Grid container sx={{ minHeight: '470px' }}>
            <Grid item xs={2} sx={{ borderRight: '1px solid #f1f4f6', padding: 2 }}>
                <Typography variant="h6">Library</Typography>
                <List component="nav">
                    <ListItem
                        button
                        onClick={() => handleSectionClick('Policies')}
                        sx={{
                          borderRadius: '4px',
                          backgroundColor: expandedSection === 'Policies' ? '#ECEFF1' : 'inherit',
                          '&:hover': {
                            backgroundColor: '#f1f4f6',
                          },
                        }}
                    >
                        <ListItemText primary="Policies" />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => handleSectionClick('Templates')}
                        sx={{
                          borderRadius: '4px',
                          backgroundColor: expandedSection === 'Templates' ? '#ECEFF1' : 'inherit',
                          '&:hover': {
                            backgroundColor: '#f1f4f6',
                          },
                        }}
                    >
                        <ListItemText primary="Templates" />
                    </ListItem>
                </List>
            </Grid>

            <Grid item xs={10} sx={{ padding: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                    <TextField
                        variant="outlined"
                        placeholder="Search by title or description..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        InputProps={{
                          startAdornment: <SearchIcon />,
                          sx: { height: '45px' },
                        }}
                        sx={{
                          width: '50%',
                          minWidth: '300px',
                          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#e8e8e8',
                            },
                            '&:hover fieldset': {
                              borderColor: 'transparent',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'transparent',
                            },
                          },
                          borderRadius: '4px',
                        }}
                    />
                    <a target="_blank" href={getHelpURL(user?.type)} rel="noreferrer">
                        <Button
                            variant="text"
                            startIcon={<ReviewsIcon />}
                            sx={{ ml: 2 }}
                            onClick={()=> { return false; }}
                        >
                            Feedback
                        </Button>
                    </a>
                </Box>
                {(!documentId || !selectedDocument) ? (
                    <Box>
                        <Typography variant="body1" sx={{ mb: 2, fontWeight: 500 }}>
                            {expandedSection}
                        </Typography>
                        <Grid container spacing={3}>
                            {filteredSections.map((item, index) => (
                                <Grid item xs={12} sm={6} md={3} key={item.id}>
                                    <Card
                                        sx={{
                                          height: '100%',
                                          maxHeight: 375,
                                          border: '1px solid #f5f5f5',
                                          padding: 2,
                                          cursor: 'pointer',
                                          transition: 'transform 0.2s, box-shadow 0.2s',
                                          '&:hover': {
                                            transform: 'scale(1.02)',
                                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                                          },
                                        }}
                                        onClick={() => handleCardClick(item)}
                                    >
                                        <Box
                                            sx={{
                                              background: `linear-gradient(to bottom left, ${gradientColors[index % gradientColors.length].start}, ${gradientColors[index % gradientColors.length].end})`,
                                              padding: 1,
                                              borderRadius: 1,
                                            }}
                                        >
                                            <CardMedia
                                                component="img"
                                                height="130"
                                                image={`${process.env.PUBLIC_URL}/assets/Screenshot-${index + 1}.png`}
                                                alt="PDF preview"
                                                sx={{ objectFit: 'contain' }}
                                            />
                                        </Box>
                                        <CardContent
                                            sx={{
                                              display: 'flex',
                                              flexDirection: 'column',
                                              justifyContent: 'space-between',
                                              flex: 1,
                                              height: 'calc(100% - 140px)',
                                              pt: 2,
                                              px: 1,
                                            }}
                                        >
                                            <Box sx={{ flexGrow: 1 }}>
                                                <Typography variant="h6" sx={{ fontWeight: '500' }}>
                                                    {item.title}
                                                </Typography>
                                                <Typography variant="body2" sx={{ marginTop: 1 }}>
                                                    {item.shortDescription}
                                                </Typography>
                                            </Box>

                                            <Box sx={{ justifyContent: 'space-between' }}>
                                                <Typography variant="caption" sx={{ color: 'gray' }}>
                                                    <DownloadIcon sx={{ fontSize: 'small', verticalAlign: 'middle', mr: 0.5 }} />{Math.floor(Math.random() * 1000)}
                                                </Typography>
                                            </Box>
                                        </CardContent>

                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                ) : (
                    <PartnerResource selectedDocument={selectedDocument} handleBackClick={handleBackClick} />
                )}
            </Grid>
        </Grid>
        <StandardDialogActions>
            <Button variant="outlined" onClick={handleCloseResourceModal}>Close</Button>
        </StandardDialogActions>
    </StandardDialog>
  );
}