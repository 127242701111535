import React, { useEffect, useState } from 'react';
import { Button, Container, Typography } from '@mui/material';
import { DataCell, DataListContain, DataRow } from '../Tables/DataList';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import getAuthSession from '../../services/auth';
import { fetchQuestionnaires, QuestionnaireTypes } from '../../services/questionnaires';

export default function Reports() {
  const [MPABPQuestionnaires, setMPABPQuestionnaires] = useState<any[]>([]);
  const [selectedMPABPQuestionnaire, setSelectedMPABPQuestionnaire] = useState('');

  useEffect(() => {fetchQuestionnaires(QuestionnaireTypes.MPA_BEST_PRACTICE).then((questionnaires) => setMPABPQuestionnaires(questionnaires));}, []);

  const downloadFile = (data: any, filename: string) => {
    // Convert CSV data to a Blob object
    const blob = new Blob([data], { type: 'text/csv' });
    // Create a URL object from the Blob object
    const url = window.URL.createObjectURL(blob);
    // Create a temporary link and click it programmatically to download the file
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  };

  const fetchAndDownloadFile = async (reportName: string, reportUrlPartial: string) => {
    const authSession = await getAuthSession();
    await fetch(`${process.env.REACT_APP_BASE_API}/reporting/${reportUrlPartial}/`,
      {
        headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
      },
    )
      .then(response => response.text())
      .then(csvData => downloadFile(csvData, reportName + '.csv'));
  };

  const getMPABPReport = async () => {
    if (!selectedMPABPQuestionnaire) return;
    const authSession = await getAuthSession();
    await fetch(`${process.env.REACT_APP_BASE_API}/assessment-surveys/questionnaire/${selectedMPABPQuestionnaire}/reporting/csv/`,
      {
        headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
      },
    )
      .then(response => response.text())
      .then(csvData => downloadFile(csvData, 'mpa_best_practice_questionnaire_report.csv'));
  };

  const getAllCompaniesReport = async () => {
    const authSession = await getAuthSession();
    await fetch(`${process.env.REACT_APP_BASE_API}/reporting/all-companies-report/`,
      {
        headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
      },
    )
      .then(response => response.text())
      .then(csvData => downloadFile(csvData, 'all-companies-report.csv'));
  };

  const handleQuestionnaireSelect = (event: SelectChangeEvent) => {
    setSelectedMPABPQuestionnaire(event.target.value);
  };

  return (
    <Container>
      <DataListContain title="Reports">
        <DataRow>
          <DataCell xs={8}>
            <Typography variant='body1' mb={2}>MPA Best Practice Questionnaire Report</Typography>
            {MPABPQuestionnaires &&
                <FormControl sx={{ ml: 1, minWidth: 300 }} size="small">
                    <InputLabel id="questionnaire">Questionnaire</InputLabel>
                    <Select
                        labelId="questionnaire-select-label"
                        id="questionnaire-select"
                        value={selectedMPABPQuestionnaire}
                        label="Questionniare"
                        onChange={handleQuestionnaireSelect}
                    >
                      {MPABPQuestionnaires.map((questionnaire, idx) => (<MenuItem key={idx} value={questionnaire.id}>{questionnaire.title} - {questionnaire.version}</MenuItem>))}
                    </Select>
                </FormControl>}
          </DataCell>
          <DataCell xs={4}>
            <Button variant='contained' onClick={() => getMPABPReport()}>Get Report</Button>
          </DataCell>
        </DataRow>
        <DataRow>
          <DataCell xs={8}>All Companies Report</DataCell>
          <DataCell xs={4}>
            <Button variant='contained' onClick={() => getAllCompaniesReport()}>Get Report</Button>
          </DataCell>
        </DataRow>
        <DataRow>
          <DataCell xs={8}>Applications By Licensee Report</DataCell>
          <DataCell xs={4}>
            <Button variant='contained' onClick={() => fetchAndDownloadFile('applications-by-licensee-report', 'applications-by-licensee-report')}>Get Report</Button>
          </DataCell>
        </DataRow>
      </DataListContain>
    </Container>
  );
}
