import React, { useState, useCallback, useEffect } from 'react';
import { Answer } from '../../interfaces/answer.interface';
import { Question } from '../../interfaces/question.interface';
import update from 'immutability-helper';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { DataRow, DataCell } from '../Tables/DataList';
import AnswerListItem from './AnswerListItem';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useAppDispatch } from '../../app/hooks';
import { fetchAll } from '../../app/store/questionnaireSlice';
import { IncludedInLogic } from '../Questions/Question';
import { Questionnaire } from '../../interfaces/questionnaire.interface';

export default function AnswerList(props: { includedInLogic: IncludedInLogic[], answers: Answer[], canEdit?: boolean, questionUpdate: any, question: Question }) {
  const [answers, setAnswers] = useState(props.answers);
  const dispatch = useAppDispatch();
  const moveAnswer = useCallback((dragIndex: number, hoverIndex: number) => {
    setAnswers((prevAnswers: Answer[]) =>
      update(prevAnswers, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevAnswers[dragIndex] as Answer],
        ],
      }),
    );
  }, []);

  const dropAnswer = () => {
    props.questionUpdate({ answers });
  };

  const removeAnswer = (answerId:number) => {
    const answerIndex = answers.findIndex(answer => answer.id === answerId);
    let newAnswerList = [ ...answers ];
    newAnswerList.splice(answerIndex, 1);
    props.questionUpdate({ answers: newAnswerList });
  };

  const isBaseline = React.useMemo(() => ['site_baseline', 'application_baseline'].includes((props.question?.questionnaire as Questionnaire).type || ''), [props.question]);

  useEffect(() => {
    if (!props.answers || typeof props.answers[0] === 'number') return;
    setAnswers(props.answers);
  }, [props.answers]);

  useEffect(() => {
    dispatch(fetchAll({}));
  }, []);

  return (
    <>
      <DataRow header>
        <DataCell xs={ isBaseline ? 7 : 9 }>Text</DataCell>
        { isBaseline && <DataCell xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>De-scope
          <Tooltip
            title={
              <Box
                sx={{
                  padding: 1,
                  width: '300px',
                }}
              >
                <Typography variant="caption">
                  Hides a question when the selected Baseline Answer below is chosen.
                </Typography>
              </Box>
            }
            arrow
            placement="top"
          >
            <IconButton size="small" sx={{ marginLeft: 0 }}>
              <HelpOutlineIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </DataCell>}
        <DataCell xs={3}>Is Compliant</DataCell>
      </DataRow>
      <DndProvider backend={HTML5Backend}>
        { answers.map((answer: Answer, idx: number) => (
          <AnswerListItem
            canEdit={props.canEdit}
            includedInLogic={props.includedInLogic}
            key={answer.id}
            answer={answer}
            answers={answers}
            question={props.question}
            idx={idx}
            moveItem={moveAnswer}
            dropItem={dropAnswer}
            removeAnswer={removeAnswer}
            updateQuestion={props.questionUpdate}
          />
        ))}
      </DndProvider>
    </>
  );
}