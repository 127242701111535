import React, { useEffect, useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { Box } from '@mui/material';

interface PdfViewerProps {
  previewFile: string;
  handleDocumentLoadSuccess: (doc: any) => void;
  zoom: number;
  currentPage: number;
}

const PDF_WORKER_URL = 'https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js';

const PdfViewer: React.FC<PdfViewerProps> = ({
  previewFile,
  handleDocumentLoadSuccess,
  zoom,
  currentPage,
}) => {
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;

  const zoomPluginInstance = zoomPlugin();
  const { zoomTo } = zoomPluginInstance;

  useEffect(() => {
    zoomTo(zoom);
  }, [zoom]);

  useEffect(() => {
    jumpToPage(currentPage);
  }, [currentPage]);

  const [pdfDataUrl, setPdfDataUrl] = useState<string | null>(null);
  useEffect(() => {
    const fetchPdfAsDataUrl = async () => {
      try {
        const response = await fetch(previewFile);
        if (!response.ok) {
          throw new Error(`Failed to fetch PDF: ${response.statusText}`);
        }
        const blob = await response.blob();

        const reader = new FileReader();
        reader.onloadend = () => {
          setPdfDataUrl(reader.result as string);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    };
    fetchPdfAsDataUrl();
  }, [previewFile]);

  return (
    <Box sx={{ position: 'relative', zIndex: 1, height: '100%', overflowY: 'auto', width: '100%', backgroundColor: '#f0f0f0' }}>
      { pdfDataUrl === null ? null : (
              <Worker workerUrl={PDF_WORKER_URL}>
              <Viewer fileUrl={pdfDataUrl} plugins={[pageNavigationPluginInstance, zoomPluginInstance]} onDocumentLoad={handleDocumentLoadSuccess} initialPage={currentPage} defaultScale={zoom} />
            </Worker>
      )}
    </Box>
  );
};

export default PdfViewer;
