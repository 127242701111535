import { Questionnaire } from '../interfaces/questionnaire.interface';
import { Domain } from '../interfaces/domain.interface';
import { Topic } from '../interfaces/topic.interface';
import { BestPractice } from '../interfaces/bestPractice.interface';
import { Question } from '../interfaces/question.interface';
import { QuestionStatusById } from '../app/store/questionnaireAnswerSlice';
import { QuestionAnswer } from '../interfaces/questionAnswer.interface';
import { setQuestionStatus } from './questionnaireQuestionStatus';
import getAuthSession from './auth';
import axios from 'axios';
import { InclusionConfiguration } from '../interfaces/inclusionConfiguration.interface';

interface QuestionById {
  [id: string]: Question;
}

export const buildIdObjects = (questions: Question[], questionAnswers?: QuestionAnswer[]) => {
  let questionsById:QuestionById = {};
  let questionStatusById:QuestionStatusById = {};

  questions.forEach(question => {
    questionsById[question.id] = question;
    
    questionStatusById[question.id] = {
      status: question.inclusions && question.inclusions.length > 0 ? 'hide' : 'incomplete',
      question,
      questionAnswer: questionAnswers ? questionAnswers.find(qa => qa.question === question.id) : undefined,
    };
    setQuestionStatus(questionStatusById, question.id);
  });

  return { questionsById, questionStatusById };
};

export const buildOutQuestionnaire = (questionnaire: Questionnaire, questionsList: Question[], questionAnswers?: QuestionAnswer[]) => {
  const idObjects = buildIdObjects(questionsList, questionAnswers);
  let newQuestionnaire:Questionnaire = { ...questionnaire };
  let domains:Domain[] = [];
  let certControls:any = [];

  newQuestionnaire.domains.forEach(domain => {
    let newDomain = { ...domain };
    let topics:Topic[] = [];
    if (newDomain) {
      newDomain.topics.forEach(topic => {
        let newTopic = { ...topic };
        let bestPractices:BestPractice[] = [];
        if (newTopic) {
          newTopic.bestPractices.forEach(bestPractice => {
            let newbp = { ...bestPractice };
            let questions:Question[] = [];
            if (newbp) {
              newbp.questions.forEach(question => {
                let newQuestion = idObjects.questionsById[typeof question === 'number' ? question : question.id];
                if (newQuestion) {
                  questions.push(newQuestion);
                  if (newQuestion.excludedByCertifications) certControls = [...new Set([ ...certControls, ...newQuestion.excludedByCertifications])]; 
                } else {
                  questions.push(question);
                }
              });
              newbp = { ...newbp, questions };
              bestPractices.push(newbp);
            }
          });
          newTopic = { ...newTopic, bestPractices };
          topics.push(newTopic);
        }
      });
      newDomain = { ...newDomain, topics };
      domains.push(newDomain);
    }
  });
  newQuestionnaire = { ...newQuestionnaire, domains };

  return { questionnaire: newQuestionnaire, certControls, questionStatusById: idObjects.questionStatusById };
};

export const initialBuildOutQuestionnaire = (props: { questionnaire: Questionnaire, domains: Domain[] }) => {
  const { questionnaire, domains } = props;
  //let firstQuestions:number[] = [];  KYLE: Commented out all the first logic in case we want it back.
  let allQuestions:number[] = [];
  //let firstInclusions:number[] = [];
  let allInclusions:number[] = [];
  //let firstCertifications:number[] = [];
  let allCertifications:number[] = [];


  let nDomains:Domain[] = [];
  questionnaire.domains.forEach(domain => {
    let newDomain = domains.find(d => typeof domain === 'number' ? domain === d.id : domain.id === d.id);
    let ntopics:Topic[] = [];
    if (newDomain) {
      newDomain.topics.forEach(topic => {
        let newTopic = newDomain?.topics.find(t => typeof topic === 'number' ? topic === t.id : topic.id === t.id);
        let nbestPractices:BestPractice[] = [];
        if (newTopic) {
          newTopic.bestPractices.forEach(bestPractice => {
            let nQuestions:Question[] = [];
            let newbp = newTopic?.bestPractices.find(bp => typeof bestPractice === 'number' ? bestPractice === bp.id : bestPractice.id === bp.id);
            if (newbp) {
              newbp.questions.forEach(question => {
                nQuestions.push(question);
                // if (
                //   (typeof questionnaire.domains[0] === 'number' ? questionnaire.domains[0] === newDomain?.id : questionnaire.domains[0].id === newDomain?.id) &&
                //   (typeof newDomain?.topics[0] === 'number' ? newDomain.topics[0] === newTopic?.id : newDomain?.topics[0].id === newTopic?.id) &&
                //   (typeof newTopic?.bestPractices[0] === 'number' ? newTopic?.bestPractices[0] === newbp?.id : newTopic?.bestPractices[0].id === newbp?.id)
                // ) {
                //   if (newbp && newbp.questions && newbp.questions.length) firstQuestions = [...new Set([ ...firstQuestions, ...(newbp.questions.map(qu => typeof qu === 'number' ? qu : qu.id))])];
                // } else {
                if (newbp && newbp.questions && newbp.questions.length) allQuestions = [...new Set([ ...allQuestions, ...(newbp.questions.map(qu => typeof qu === 'number' ? qu : qu.id))])];
                //}
                // if (newbp && newbp.inclusions && newbp.inclusions.length > 0) firstInclusions = [...new Set([ ...firstInclusions, ...newbp.inclusions.map(inc => typeof inc === 'number' ? inc : inc.id) ])];
                // if (newbp && newbp.excludedByCertifications) firstCertifications = [...new Set([ ...firstCertifications, ...newbp.excludedByCertifications.map(cert => typeof cert === 'number' ? cert : cert.id)])];
                if (newbp && newbp.inclusions && newbp.inclusions.length > 0) allInclusions = [...new Set([ ...allInclusions, ...newbp.inclusions.map(inc => typeof inc === 'number' ? inc : inc.id) ])];
                if (newbp && newbp.excludedByCertifications) allCertifications = [...new Set([ ...allCertifications, ...newbp.excludedByCertifications.map(cert => typeof cert === 'number' ? cert : cert.id)])];
              });
              if (nQuestions.length > 0) {
                newbp = { ...newbp, questions: nQuestions };
                nbestPractices.push(newbp);
              }
            }
          });
          if (nbestPractices.length > 0) {
            newTopic = { ...newTopic, bestPractices: nbestPractices };
            ntopics.push(newTopic);
          }
        }
      });
      if (ntopics.length > 0) {
        newDomain = { ...newDomain, topics: ntopics };
        nDomains.push(newDomain);
      }
    }
  });
  const newQuestionnaire = { ...questionnaire, domains: nDomains };

  return {
    questionnaire: newQuestionnaire,
    //firstQuestions,
    allQuestions,
    //firstInclusions,
    allInclusions,
    //firstCertifications,
    allCertifications,
  };
};

export const buildRequestArrays = (ids:number[], limit:number, baseUrl:string) => {
  const requstURLs:string[] = [];
  for (let count = 0; count * limit < ids.length; count++) {
    const idList = ids.filter((id, index) => count * limit <= index && index < count * limit + limit ? true : false  );
    requstURLs.push(baseUrl + idList.join(',') + `&limit=${limit}`);
  }

  return requstURLs;
};

export const fetchQuestionDependantObjects = async (noLimit:boolean, questions:number[], inclusions?:number[]) => {
  const authSession = await getAuthSession();
  const limit = noLimit ? 9999 : 15;
  const questionUrls = buildRequestArrays(questions, limit, `${process.env.REACT_APP_BASE_API}/questions/?ids=`);
  const inclusionUrls = inclusions ? buildRequestArrays(inclusions, limit, `${process.env.REACT_APP_BASE_API}/inclusion-configurations/?ids=`) : [];
  return Promise.all([
    Promise.all(inclusionUrls.map(url => axios.get(
      url,
      {
        headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
      },
    ))).then(res => {
      return res.map(resq => resq.data.results).flat(1) as InclusionConfiguration[];
    }),
    Promise.all(questionUrls.map(url => axios.get(
      url,
      {
        headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
      },
    ))).then(res => {
      return res.map(resq => resq.data.results).flat(1) as Question[];
    }),
  ]).then(res => {
    return { inclusions: res[0], questions: res[1] };
  });
};

export const buildFullQuestionnaireObject = async (questionnaireid: number) => {
  const authSession = await getAuthSession();
  return Promise.all([
    axios.get(
      `${process.env.REACT_APP_BASE_API}/domains/?questionnaire=${questionnaireid}&limit=200`,
      {
        headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
      },
    ),
  ]).then(res => {
    return { domains: res[0].data.results as Domain[] };
  });
};