import axios from 'axios';
import { FieldValues } from 'react-hook-form';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import User, { Company, SignupErrors } from '../../interfaces/users.interface';
import getAuthSession from '../../services/auth';
import { Vendor } from '../../interfaces/vendor.interface';

interface UserState {
  token?: string,
  user?: User,
  isFetching: boolean,
  isSuccessful: boolean,
  errors?: SignupErrors,
}

const initialState: UserState = {
  isFetching: false,
  isSuccessful: false,
};

interface VendorCompany {
  vendor: Vendor;
  company: Company;
}

const baseUrl = process.env.REACT_APP_BASE_API;

export const fetchWhiteListedFileTypes = createAsyncThunk(
  'users/fetchWhiteListedFileTypes',
  async () => {
    const authSession = await getAuthSession();
    try {
      const response = await axios.get(
        `${baseUrl}/white-listed-extensions/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data.results.map((bp: { id: number, extension: string }) => bp.extension);
    } catch (error: any) {
      return error;
    }
  },
);

export const fetchUserDataByToken = createAsyncThunk(
  'users/fetchUserData',
  async () => {
    const authSession = await getAuthSession();
    const response = await axios.get(
      `${baseUrl}/auth/cognito-token/`,
      {
        headers: {
          'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
        },
      },
    );
    return (await response.data) as User;
  },
);

export const authenticateUser = createAsyncThunk(
  'users/fetchAuthToken',
  async ({ username, password }: FieldValues) => {
    const response = await axios.post(
      `${baseUrl}/users/token-auth/`, {
        username: username,
        password: password,
      });
    localStorage.setItem('authToken', response.data.authToken);
    return (await response.data) as User;
  },
);

export const signupUser = createAsyncThunk(
  'users/signupUser',
  async ({ username, email, firstName, lastName, password }: FieldValues, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseUrl}/users/`, {
          username: username,
          email: email,
          firstName: firstName,
          lastName: lastName,
          password: password,
        });
      localStorage.setItem('authToken', response.data.authToken);
      return (await response.data) as User;
    } catch (error: any) {
      // error.response.data has the actual error from the API
      return rejectWithValue(error.response.data);
    }
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserVendorProfile: (state, action: PayloadAction<VendorCompany>) => {
      if (!state.user) return;
      state.user.vendorAdminProfile.vendor = action.payload.vendor;
      state.user.vendorAdminProfile.company = action.payload.company;
    },
    fetchUserDataByToken: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    signupUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    clearState: (state) => {
      state.isSuccessful = false;
      state.isFetching = false;
    },
    clearErrors: (state) => {
      state.errors = undefined;
    },
    logoutUser: (state) => {
      localStorage.removeItem('amplifyTokenExpiry');
      localStorage.removeItem('amplifyAuthToken');  
      state.user = undefined;
    },
    authenticateUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    updateUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    updateUserCompanies: (state, action: PayloadAction<Company>) => {
      if (state.user) {
        state.user.companies = [...state.user.companies, action.payload];
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchUserDataByToken.fulfilled, (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    });
    builder.addCase(signupUser.fulfilled, (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      state.isFetching = false;
      state.isSuccessful = true;
    });
    builder.addCase(signupUser.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(signupUser.rejected, (state, action: any) => {
      state.isFetching = false;
      state.isSuccessful = false;
      state.errors = action.payload;
    });
    builder.addCase(authenticateUser.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(authenticateUser.fulfilled, (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.isFetching = false;
      state.isSuccessful = true;
    });
  },
});

export const { clearState, logoutUser, clearErrors, updateUserVendorProfile, updateUser, updateUserCompanies } = userSlice.actions;

export default userSlice.reducer;
