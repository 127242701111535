import axios from 'axios';

import getAuthSession from './auth';
import { RequestToken } from '../interfaces/requestToken.interface';

export default async function getRequestTokens(): Promise<RequestToken[]> {
  const authSession = await getAuthSession();

  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/request-tokens/?limit=99999`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data.results as RequestToken[];
}

export async function patchRequestToken(requestToken: RequestToken) {
  const authSession = await getAuthSession();

  const response = await axios.patch(
    `${process.env.REACT_APP_BASE_API}/request-tokens/${requestToken.id}/`,
    requestToken,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as RequestToken;
}

export async function createRequestToken(requestToken: RequestToken) {
  const authSession = await getAuthSession();

  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/request-tokens/`,
    requestToken,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as RequestToken;
}

export async function deleteRequestToken(requestToken: RequestToken) {
  const authSession = await getAuthSession();

  const response = await axios.delete(
    `${process.env.REACT_APP_BASE_API}/request-tokens/${requestToken.id}`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}

export async function sendReportToExternalUser(requestToken: RequestToken) {
  const authSession = await getAuthSession();

  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/reports/share-report/request-token/${requestToken.id}/`,
    {},
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}

export async function requestTokenBulkUpload(file: File) {
  const authSession = await getAuthSession();

  const formData = new FormData();
  formData.append('file', file);

  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/reporting/request-token/bulk-upload/`,
    formData,
    {
      headers: {
        'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data.results as RequestToken[];
}
