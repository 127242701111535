import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import parse from 'html-react-parser';
import mammoth from 'mammoth';

interface DocxViewerProps {
  currentPage: number;
  previewFile: string;
  handleSetTotalPages: (numPages: number) => void;
}

const DocxViewer: React.FC<DocxViewerProps> = ({
  previewFile,
  currentPage,
  handleSetTotalPages,
}) => {
  const [docxPages, setDocxPages] = useState<string[]>([]);

  useEffect(() => {
    const fetchDocxContent = async () => {
      try {
        const response = await fetch(previewFile);
        if (!response.ok) {
          throw new Error(`Failed to fetch DOCX: ${response.statusText}`);
        }
        const arrayBuffer = await response.arrayBuffer();
        const { value } = await mammoth.convertToHtml({ arrayBuffer });
        const pages = value.split('<hr class="docx-page-break">');
        setDocxPages(pages);
        handleSetTotalPages(pages.length);
      } catch (error) {
        console.error('Error converting DOCX:', error);
      }
    };

    fetchDocxContent();
  }, [previewFile, handleSetTotalPages]);

  const docxStyle = {
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    padding: '16px',
    margin: '16px auto',
    maxWidth: '800px',
  };

  return (
    <Box sx={{ ...docxStyle, position: 'relative', zIndex: 1, height: '100%', overflowY: 'auto' }}>
      {docxPages[currentPage] ? parse(docxPages[currentPage]) : <Typography>...Loading</Typography>}
    </Box>
  );
};

export default DocxViewer;
