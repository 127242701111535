import React from 'react';
import { Autocomplete, Box, Button, CircularProgress, Divider, FormControl, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField, Typography, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { BPVerification } from '../../interfaces/assessment.interface';
import StandardDialog, { StandardDialogActions } from '../Modals/StandardDialog';
import { BestPractice } from '../../interfaces/bestPractice.interface';
import { Questionnaire } from '../../interfaces/questionnaire.interface';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

interface BestPracticeVerificationDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  questionnaires: Questionnaire[];
  questionnaireId: string;
  setQuestionnaireId: (id: string) => void;
  controlList: BPVerification[];
  handleFetchBestPracticeQuestions: (id: string) => void;
  handleBestPracticeChange: (value: BestPractice | null) => void;
  handleDeleteBPVerification: (id: string) => void;
  bestPracticeList: BestPractice[];
  setBestPracticeList: (list: BestPractice[]) => void;
  handleInputChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  isFetching: boolean;
  autocompleteValue: BestPractice | null;
  setAutocompleteValue: (value: BestPractice | null) => void;
  loadingBP: boolean;
}

const BestPracticeVerificationDialog: React.FC<BestPracticeVerificationDialogProps> = ({
  isOpen,
  handleClose,
  questionnaires,
  questionnaireId,
  setQuestionnaireId,
  controlList,
  handleFetchBestPracticeQuestions,
  handleBestPracticeChange,
  handleDeleteBPVerification,
  bestPracticeList,
  handleInputChange,
  isFetching,
  autocompleteValue,
  setBestPracticeList,
  setAutocompleteValue,
  loadingBP,
}) => (
  <StandardDialog
    title="Best Practice Validation List"
    subtitle="View, add or remove Best Practice Validations for the Questionnaire selected below."
    handleClose={handleClose}
    isOpen={isOpen}
  >
    <Box p={2}>
      <Stack spacing={2}>
        <Box>
          <FormControl fullWidth>
            <InputLabel id="select-bp-label">Questionnaire</InputLabel>
            <Select
              labelId="select-bp-label"
              label="Questionnaire"
              value={questionnaireId}
              onChange={(e) => {
                const id = e.target.value as string;
                setAutocompleteValue(null);
                setBestPracticeList([]);
                setQuestionnaireId(id);
                handleFetchBestPracticeQuestions(id);
              }}
            >
              {questionnaires.map((q) => (
                <MenuItem key={q.id} value={q.id}>
                  {q.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Divider />
        {loadingBP ? (
          <CircularProgress size={18} />
        ) : (
          <>
            <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              Validation Best Practices {controlList.length > 0 && `(${controlList.length})`}
              <Tooltip title="Remediation plans marked as 'Remediated' for these Best Practices will undergo validation." placement='top'>
                <InfoIcon
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      color: '#000',
                    },
                  }}
                />
              </Tooltip>
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {controlList.length > 0 ? (
                controlList
                  .sort((a, b) => a.title.localeCompare(b.title))
                  .map((control) => (
                    <Box
                      key={control.id}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{
                        borderRadius: '6px',
                        cursor: 'pointer',
                        mb: 1,
                        p: 1,
                        backgroundColor: '#f9f9f9',
                        border: '1px solid #e0e0e0',
                        '&:hover': { backgroundColor: '#f1f1f1' },
                        '&:hover .delete-icon': { opacity: 1 },
                      }}
                    >
                      <Typography variant="body1" sx={{ px: 1, flexGrow: 1 }}>
                        {control.title}
                      </Typography>
                      <IconButton
                        className="delete-icon"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteBPVerification(control.id);
                        }}
                        edge="end"
                        sx={{ opacity: 0, transition: 'opacity 0.3s ease' }}
                      >
                        <ClearIcon sx={{ fontSize: '16px' }} />
                      </IconButton>
                    </Box>
                  ))
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No Validation Best Practices found.
                </Typography>
              )}
            </Box>
            <Divider />
            <Box>
              <Typography variant="h6" gutterBottom>
                Add Validation Best Practice
              </Typography>
              <Autocomplete
                id="best-practice-autocomplete"
                options={bestPracticeList}
                fullWidth
                value={autocompleteValue}
                onChange={(event, value) => {
                  setAutocompleteValue(value);
                  handleBestPracticeChange(value);
                }}
                getOptionLabel={(option) => option.title || 'No Title'}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option) => (
                  <li {...props} key={option.id}>
                    {option.title || 'No Title'}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Search Best Practices"
                    onChange={handleInputChange}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: isFetching ? <CircularProgress size={20} /> : params.InputProps.startAdornment,
                      endAdornment: (
                        <>
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          </>
        )}
      </Stack>
    </Box>
    <StandardDialogActions>
      <Button variant="outlined" onClick={handleClose}>
        Cancel
      </Button>
    </StandardDialogActions>
  </StandardDialog>
);
export default BestPracticeVerificationDialog;
