import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REMEDIATION_VERIFICATION_STATUS_LABELS, REMEDIATION_OPTIONS } from '../../services/surveyHelpers';

type ValueOf<T> = T[keyof T];
export type VerificationStatus = keyof typeof REMEDIATION_VERIFICATION_STATUS_LABELS | null;
export type PlanStatus = ValueOf<typeof REMEDIATION_OPTIONS> | null | 'Not Started';
export type QuestionTypes = 'Best Practice' | 'Additional Recommendation' | null;

interface RemediationFilterSlice {
  filteredVerificationStatus: VerificationStatus;
  filteredPlanStatus: PlanStatus;
  filteredQuestionType: QuestionTypes;
  filteredPastDue: boolean | null;
  filteredQuestions: { [key: string]: boolean };
  filteredSubRemediationAnswers: { [key: string]: boolean };
}

const initialState: RemediationFilterSlice = {
  filteredVerificationStatus: null,
  filteredPlanStatus: null,
  filteredPastDue: null,
  filteredQuestionType: null,
  filteredQuestions: {},
  filteredSubRemediationAnswers: {},
};

const remediationFilterSlice = createSlice({
  name: 'remediationFilterSlice',
  initialState,
  reducers: {
    setFilteredQuestionType(state, action: PayloadAction<QuestionTypes>) {
      state.filteredQuestionType = action.payload;
    },
    setFilteredRemediationAnswers(state, action: PayloadAction<{ [key: string]: boolean }>) {
      state.filteredSubRemediationAnswers = action.payload;
    },
    setFilteredQuestions(state, action: PayloadAction<{ [key: string]: boolean }>) {
      state.filteredQuestions = action.payload;
    },
    setVerificationStatus(state, action: PayloadAction<VerificationStatus>) {
      state.filteredVerificationStatus = action.payload;
    },
    setPlanStatus(state, action: PayloadAction<PlanStatus>) {
      state.filteredPlanStatus = action.payload;
    },
    setPastDue(state, action: PayloadAction<boolean | null>) {
      state.filteredPastDue = action.payload;
    },
    clearFilters(state) {
      state.filteredVerificationStatus = null;
      state.filteredPlanStatus = null;
      state.filteredQuestionType = null;
      state.filteredPastDue = null;
      state.filteredQuestions = {};
      state.filteredSubRemediationAnswers = {};
    },
  },
});

export const {
  setFilteredRemediationAnswers,
  setFilteredQuestionType,
  setVerificationStatus,
  setFilteredQuestions,
  setPlanStatus,
  setPastDue,
  clearFilters,
} = remediationFilterSlice.actions;

export default remediationFilterSlice.reducer;
