import { Domain } from './domain.interface';

export interface Questionnaire {
  id: number;
  title: string;
  description: string;
  version: string;
  slug: string;
  domains: Domain[];
  errors?: any;
  isPublished?: boolean;
  type?: string;
  versions?: Questionnaire[];
  bestPracticeGuidanceLink?: string;
  includeInReporting?: boolean;
}

export interface QuestionnaireResultArray {
  count: number;
  next: string;
  previous: string;
  results: Questionnaire[];
}

export enum ReportVersion {
  v5_1 = 'v5.1',
  v5_2 = 'v5.2',
}

export interface RemediationValidationRow {
  id?: number | string;
  siteOrAppName: string;
  questionnaireTitle?: string;
  companyName: string;
  annotatedAssessmentSurveyId: number;
  needsList: any[];
  assessmentDate: string;
  annotatedUpdatedAt: string;
  annotatedAssessmentId: number;
  annotatedBpSurveyId: number;
  annotatedVendorStatus: string;
  assessorAnswerVerificationDate: string | null;
  assessorAnswerVerificationStatus: string;
  questionShortCode: string;
  remediationAnswerId: number | null;
  subRemediationDate: string | null;
  subRemediationPlan: string | null;
  subRemediationVerificationDate: string | null;
  subRemediationVerificationStatus: string | null;
}