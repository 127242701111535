import { AssessmentQuestionStatusById, BPStatusById, CertControlById, InclusionConfigsById, QuestionStatusById } from '../app/store/questionnaireAnswerSlice';
import { CertificationControl } from '../interfaces/certificationControl.interface';
import { Question } from '../interfaces/question.interface';
import { Questionnaire } from '../interfaces/questionnaire.interface';
import { CompanyCertification } from '../interfaces/users.interface';

export const setQuestionStatus = (
  questionsStatus: QuestionStatusById,
  qid: string,
  companyCerts?: CompanyCertification[],
  certControls?: CertControlById,
  newStatus?: string,
) => {
  const qStat = questionsStatus[qid];
  if (qStat.questionAnswer?.status && typeof qStat.questionAnswer?.status === 'string') {
    let savedStatus = '';
    try {
      savedStatus = JSON.parse(qStat.questionAnswer?.status).status;
    } catch (e) {
      savedStatus = qStat.status;
    }
    if (savedStatus) qStat.status = savedStatus;
  }
  if (newStatus && newStatus !== 'removeRemediation') {
    qStat.status = newStatus;
    return qStat;
  }

  
  const question = qStat.question;
  let curStatus = '';
  try {
    curStatus = JSON.parse(JSON.stringify(qStat.status)).status;
  } catch (e) {
    curStatus = qStat.status;
  }
  qStat.status = 'incomplete';
  qStat.fakeAnswers = undefined;

  if (question && question.inclusions && question.inclusions.length > 0) {
    question.inclusions.forEach(inclusion => {
      if (typeof inclusion === 'number') return;
      const incQ = questionsStatus[typeof inclusion.question === 'number' ? inclusion.question : inclusion.question.id];
      const satIndex = qStat.satisfiedInclusions?.indexOf(inclusion.id);
      if (incQ) {
        const incA = incQ.questionAnswer?.answer;
        const incCcA = incQ.questionAnswer?.compensatingControlAnswers;
        const incFA = incQ.fakeAnswers;
        if (
          (incA && typeof incA === 'object' && incA.length > 0) ||
          (incCcA && typeof incCcA === 'object' && incCcA.length > 0) ||
          (incFA && typeof incFA === 'object' && incFA.length > 0 && !((typeof incA === 'object' && incA.length > 0) || (typeof incCcA === 'object' && incCcA.length > 0)))
        ) {
          if ( (incA || incCcA) && (
            (typeof inclusion.answer === 'number' && typeof incA === 'object' && incA.indexOf(inclusion.answer) > -1) ||
            (typeof inclusion.answer !== 'number' && typeof incCcA === 'object' && incCcA.indexOf(inclusion.answer.id) > -1) ||
            (typeof inclusion.answer !== 'number' && typeof incA === 'object' && incA.indexOf(inclusion.answer.id) > -1) ||
            (typeof inclusion.answer !== 'number' && typeof incCcA === 'object' && incCcA.indexOf(inclusion.answer.id) > -1)
          )) {
            if (!qStat.satisfiedInclusions) {
              qStat.satisfiedInclusions = [inclusion.id];
            } else {
              if (typeof(satIndex) === 'number' && satIndex === -1) qStat.satisfiedInclusions?.push(inclusion.id);
            }
          } else if ( incFA && !((typeof incA === 'object' && incA.length > 0) || (typeof incCcA === 'object' && incCcA.length > 0)) && (
            (typeof inclusion.answer === 'number' && incFA.indexOf(inclusion.answer) > -1) ||
            (typeof inclusion.answer !== 'number' && incFA.indexOf(inclusion.answer.id) > -1)
          )) {
            if (!qStat.satisfiedInclusions) {
              qStat.satisfiedInclusions = [inclusion.id];
            } else {
              if (typeof(satIndex) === 'number' && satIndex === -1) qStat.satisfiedInclusions?.push(inclusion.id);
            }
          } else {
            if (typeof(satIndex) === 'number' && satIndex > -1) {
              const newSatInc = qStat.satisfiedInclusions?.filter((data, idx) => idx !== satIndex );
              qStat.satisfiedInclusions = newSatInc;
            }
          }
        } else {
          if (typeof(satIndex) === 'number' && satIndex > -1) {
            const newSatInc = qStat.satisfiedInclusions?.filter((data, idx) => idx !== satIndex );
            qStat.satisfiedInclusions = newSatInc;
          }
        }
      }
    });
    if (qStat.satisfiedInclusions === undefined || (qStat.satisfiedInclusions as number[]).length === 0) {
      qStat.status = 'hide';
      return qStat;
    }
  }
  if (['submitted', 'remediation', 'priority'].indexOf(curStatus) > -1 && !newStatus) {
    qStat.status = curStatus;
    return qStat;
  }

  if (question?.excludedByCertifications && question.excludedByCertifications.length > 0) {
    question.excludedByCertifications.forEach(ccontrol => {
      const fccontrol:CertificationControl | boolean = certControls ? certControls[typeof ccontrol === 'number' ? ccontrol : ccontrol.id] : false;
      if (fccontrol && companyCerts?.find(cert => cert.certification.id === fccontrol.certification.id)) {
        qStat.status = 'cert';
        if (fccontrol.answers) {
          const answers = fccontrol.answers.map(a => typeof a === 'number' ? a : a.id);
          qStat.fakeAnswers = qStat.fakeAnswers ?
            [ ...new Set([ ...qStat.fakeAnswers, ...answers ])] : 
            [ ...answers ];
        }
      }
    });
  }

  if (qStat.questionAnswer && (
    (qStat.questionAnswer.answer && qStat.questionAnswer.answer.length > 0) ||
      (qStat.questionAnswer.compensatingControlAnswers && qStat.questionAnswer.compensatingControlAnswers.length > 0) ||
      (qStat.questionAnswer.nonApplicableAnswers && qStat.questionAnswer.nonApplicableAnswers.length > 0) ||
      (qStat.questionAnswer.nonImplementedAnswers && qStat.questionAnswer.nonImplementedAnswers.length > 0) ||
      qStat.questionAnswer.text
  )) {
    qStat.status = 'complete';
    if (!qStat.questionAnswer.answer?.length && !qStat.questionAnswer.compensatingControlAnswers?.length && !qStat.questionAnswer.nonApplicableAnswers?.length && !qStat.questionAnswer.nonImplementedAnswers?.length) {
      return qStat;
    }
    const compliantAnswers = qStat.question?.answers?.filter(a => a.isCompliant);
    if (compliantAnswers && compliantAnswers.length > 0) {
      if (question.type === 'single_select') {
        const filteredArray = compliantAnswers.filter(a => qStat.questionAnswer?.answer?.includes(a.id) || qStat.questionAnswer?.compensatingControlAnswers?.includes(a.id));
        if (!filteredArray || filteredArray.length === 0) qStat.status = 'non-compliant';
      }
      if (question.type === 'multi_select' && !compliantAnswers.every(a => qStat.questionAnswer?.nonImplementedAnswers?.includes(a.id) || qStat.questionAnswer?.answer?.includes(a.id) || qStat.questionAnswer?.compensatingControlAnswers?.includes(a.id) || qStat.questionAnswer?.nonApplicableAnswers?.includes(a.id))) qStat.status = 'non-compliant';
    }
  }
  return qStat;
};

export const setQuestionStatuses = (
  questionsStatus: QuestionStatusById,
  companyCerts?: CompanyCertification[],
  certControls?: CertControlById,
) => {
  Object.keys(questionsStatus).forEach(qid => {
    questionsStatus[qid] = setQuestionStatus(
      questionsStatus,
      qid,
      companyCerts,
      certControls,
    );
  });

  return questionsStatus;
};

export const statuses = ['complete', 'cert', 'incomplete', 'non-compliant', 'submitted', 'remediation', 'priority'];

export const shouldUseAssessmentColors = (status?:string) => {
  if (!status) return false;
  return ['Assigned', 'Rejected', 'Pre-Assessment'].indexOf(status) === -1;
};

export const setBPStatus = (
  questionnaire: Questionnaire,
  questionStatus: QuestionStatusById,
  newBPStatus: BPStatusById,
  bpInclusionIds: InclusionConfigsById,
  assessmentQuestionsStatus?: AssessmentQuestionStatusById,
  assessmentStatus?: string,
) => {
  questionnaire.domains.forEach(domain => {
    domain.topics.forEach(topic => {
      topic.bestPractices.forEach(bp => {
        const id = newBPStatus[bp.id] ? newBPStatus[bp.id].id : undefined;
        let visibleQuestionsCount = 0;
        let questionsAnsweredCount = 0;
        let status = 'incomplete';
        let firstItem = 0;
        let lastQuestion = -1;
        let firstUnanswered: Question | undefined = undefined;
        let satisfiedInclusions = newBPStatus[bp.id] ? newBPStatus[bp.id].satisfiedInclusions : [];
        let assessorApproved = shouldUseAssessmentColors(assessmentStatus);

        if (shouldUseAssessmentColors(assessmentStatus)) {
          if (bp.questions.findIndex(q => typeof q === 'number') === -1) {
            bp.questions.forEach((question) => {
              if (questionStatus[question.id].status === 'hide') { firstItem++; return; }
              visibleQuestionsCount++;
              lastQuestion = question.id;
              if (!assessmentQuestionsStatus || !assessmentQuestionsStatus[question.id]?.answer) { firstItem++; return; }
              questionsAnsweredCount++;
              if (firstUnanswered === undefined && assessmentQuestionsStatus[question.id].answer) firstUnanswered = question;
              if (assessmentQuestionsStatus && assessmentQuestionsStatus[question.id] && assessmentQuestionsStatus[question.id].status !== undefined) {
                if (assessmentQuestionsStatus[question.id].status !== 'assessorApproved') assessorApproved = false;
                if (statuses.indexOf(status) < statuses.indexOf(assessmentQuestionsStatus[question.id].status!)) status = assessmentQuestionsStatus[question.id].status!;
              } else {
                assessorApproved = false;
              }
            });
            if (questionsAnsweredCount !== visibleQuestionsCount) assessorApproved = false;
          }
        } else {
          if (bp.questions.findIndex(q => typeof q === 'number') === -1) {
            bp.questions.forEach((question, i) => {
              if (!questionStatus[question.id] || questionStatus[question.id].status === 'hide') { firstItem++; return; }
              if (firstUnanswered === undefined &&
                (
                  (!questionStatus[question.id].questionAnswer?.text || questionStatus[question.id].questionAnswer?.text?.length === 0) &&
                  (!questionStatus[question.id].questionAnswer?.answer || questionStatus[question.id].questionAnswer?.answer!.length === 0) &&
                  (!questionStatus[question.id].questionAnswer?.compensatingControlAnswers || questionStatus[question.id].questionAnswer?.compensatingControlAnswers!.length === 0) &&
                  (!questionStatus[question.id].questionAnswer?.nonApplicableAnswers || questionStatus[question.id].questionAnswer?.nonApplicableAnswers!.length === 0) &&
                  (!questionStatus[question.id].questionAnswer?.nonImplementedAnswers || questionStatus[question.id].questionAnswer?.nonImplementedAnswers!.length === 0) &&
                  (!questionStatus[question.id].fakeAnswers || questionStatus[question.id].fakeAnswers?.length === 0)
                )
              ) firstUnanswered = question;
              if (statuses.indexOf(status) < statuses.indexOf(questionStatus[question.id].status) || i === firstItem) status = questionStatus[question.id].status;
              visibleQuestionsCount++;
              if ((questionStatus[question.id].questionAnswer?.id && (questionStatus[question.id].questionAnswer?.text || questionStatus[question.id].questionAnswer?.answer || questionStatus[question.id].questionAnswer?.compensatingControlAnswers || questionStatus[question.id].questionAnswer?.nonApplicableAnswers || questionStatus[question.id].questionAnswer?.nonImplementedAnswers)) || questionStatus[question.id].status === 'cert') questionsAnsweredCount++;
              lastQuestion = question.id;
            });
          } else {
            visibleQuestionsCount = newBPStatus[bp.id] ? newBPStatus[bp.id].visibleQuestionsCount : 0;
            questionsAnsweredCount = newBPStatus[bp.id] ? newBPStatus[bp.id].questionsAnsweredCount : 0;
            status = newBPStatus[bp.id] ? newBPStatus[bp.id].status : 'incomplete';
          }
        }

        if (bp.inclusions && bp.inclusions.length > 0 && Object.keys(bpInclusionIds).length > 0) {
          (bp.inclusions as number[]).forEach((inclusionID) => {
            const inclusion = bpInclusionIds[inclusionID];
            if (inclusion) {
              const incQ = questionStatus[typeof inclusion.question === 'number' ? inclusion.question : inclusion.question.id];
              const satIndex = satisfiedInclusions.indexOf(inclusion.id);
              if (incQ) {
                const incA = incQ.questionAnswer?.answer;
                const incCcA = incQ.questionAnswer?.compensatingControlAnswers;
                const incFA = incQ.fakeAnswers;
                if (
                  (incA && typeof incA === 'object' && incA.length > 0) ||
                  (incCcA && typeof incCcA === 'object' && incCcA.length > 0) ||
                  (incFA && typeof incFA === 'object' && incFA.length > 0 && !(typeof incA === 'object' && incA.length > 0)) 
                ) {
                  if ( incA && (
                    (typeof inclusion.answer === 'number' && incA.indexOf(inclusion.answer) > -1) ||
                    (typeof inclusion.answer !== 'number' && incA.indexOf(inclusion.answer.id) > -1)
                  ) || incCcA && (
                    (typeof inclusion.answer === 'number' && incCcA.indexOf(inclusion.answer) > -1) ||
                    (typeof inclusion.answer !== 'number' && incCcA.indexOf(inclusion.answer.id) > -1)
                  )) {
                    if (!satisfiedInclusions) {
                      satisfiedInclusions = [inclusion.id];
                    } else {
                      if (typeof(satIndex) === 'number' && satIndex === -1) satisfiedInclusions.push(inclusion.id);
                    }
                  } else if ( incFA && !(typeof incA === 'object' && incA.length > 0) && (
                    (typeof inclusion.answer === 'number' && incFA.indexOf(inclusion.answer) > -1) ||
                    (typeof inclusion.answer !== 'number' && incFA.indexOf(inclusion.answer.id) > -1)
                  )) {
                    if (!satisfiedInclusions) {
                      satisfiedInclusions = [inclusion.id];
                    } else {
                      if (typeof(satIndex) === 'number' && satIndex === -1) satisfiedInclusions.push(inclusion.id);
                    }
                  } else {
                    if (typeof(satIndex) === 'number' && satIndex > -1) {
                      const newSatInc = satisfiedInclusions.filter((data, idx) => idx !== satIndex );
                      satisfiedInclusions = newSatInc;
                    }
                  }
                } else {
                  if (typeof(satIndex) === 'number' && satIndex > -1) {
                    const newSatInc = satisfiedInclusions.filter((data, idx) => idx !== satIndex );
                    satisfiedInclusions = newSatInc;
                  }
                }
              }
            }
          });
          if (satisfiedInclusions === undefined || (satisfiedInclusions as number[]).length === 0) status = 'hide';
        }

        newBPStatus[bp.id] = { id, satisfiedInclusions, status, visibleQuestionsCount, questionsAnsweredCount, color: 'info', lastQuestion, firstUnanswered, bestPractice: { ...bp, topic, domain, questionnaire } };
        if (status === 'complete') newBPStatus[bp.id].color = 'success';
        if (status === 'remediation') newBPStatus[bp.id].color = 'error';
        if (status === 'submitted') newBPStatus[bp.id].color = 'nonCompliant';
        if (status === 'cert') newBPStatus[bp.id].color = 'primary';
        if (status === 'priority') newBPStatus[bp.id].color = 'secondary';
        if (status === 'non-compliant') newBPStatus[bp.id].color = 'nonCompliant';
        if (assessorApproved) newBPStatus[bp.id].color = 'success';
      });
    });
  });

  return newBPStatus;
};