import React from 'react';
import { Box } from '@mui/material';

interface ImageViewerProps {
  previewFile: string;
  zoom: number;
}

const ImageViewer: React.FC<ImageViewerProps> = ({ previewFile, zoom }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 1,
        height: '600px',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        src={previewFile}
        alt="Preview"
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          objectFit: 'contain',
          transform: `scale(${zoom})`,
          transformOrigin: 'center center',
          transition: 'transform 0.3s ease',
          display: 'block',
          userSelect: 'none',
          pointerEvents: 'none',
        }}
        onContextMenu={(e) => e.preventDefault()}
      />
    </Box>
  );
};

export default ImageViewer;
