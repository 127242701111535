import { Box, Button, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';
import { PartnerResource as PartnerResourceInterface } from '../../interfaces/partnerResources.interface';

export default function PartnerResource({
  selectedDocument,
  handleBackClick,
}: {
  selectedDocument: PartnerResourceInterface;
  handleBackClick: () => void;
}) {
  return (
        <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button variant="text" startIcon={<ArrowBackIcon />} onClick={handleBackClick}>
                Back
            </Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', my: 2, gap: 20 }}>
            <Box>
                <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 'bold',
                      mb: 2,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                >
                    {selectedDocument.title}
                    <Box
                        component="span"
                        sx={{
                          display: 'inline-block',
                          width: '1px',
                          height: '24px',
                          backgroundColor: '#ccc',
                          marginX: 1,
                        }}
                    />
                    <Box
                        component="span"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: '#555',
                          fontSize: '1rem',
                          fontWeight: '500',
                        }}
                    >
                        <DownloadIcon sx={{ fontSize: '1.2rem', mr: 0.5 }} />
                        {Math.floor(Math.random() * 1000)} users
                    </Box>
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                      fontSize: '1rem',
                      lineHeight: 1.5,
                      textAlign: 'justify',
                    }}
                >
                    {selectedDocument.description}
                </Typography>
            </Box>
            <Box>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => alert('Downloading file...')}
                    startIcon={<DownloadIcon />}
                >
                    Download
                </Button>
            </Box>
        </Box>
        <Box
            sx={{
              mb: 4,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
        >
            <Box
                component="img"
                sx={{
                  maxHeight: '500px', // Limited max height
                  maxWidth: '100%', // Ensure responsive scaling
                  objectFit: 'contain',
                  border: '1px solid #e0e0e0', // Subtle border
                  borderRadius: '8px', // Rounded corners
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                }}
                src={`${process.env.PUBLIC_URL}/assets/Screenshot-${selectedDocument.id}.png`}
                alt="Document preview"
            />

        </Box>
    </Box>
  );
}