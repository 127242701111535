import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  Badge,
  IconButton,
} from '@mui/material';
import StandardDialog from '../Modals/StandardDialog';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  setVerificationStatus,
  setPlanStatus,
  setPastDue,
  setFilteredQuestionType,
  clearFilters,
  QuestionTypes,
  VerificationStatus,
  PlanStatus,
} from '../../app/store/remediationFilterSlice';

const filterOptions = {
  'Question Type': ['Best Practice', 'Additional Recommendation'],
  'Plan Status':   ['Remediated', 'Will Remediate Later', 'Will not Remediate', 'Not Started'],
  'Validation Status': [
    { value: 'accepted', label: 'Accepted' },
    { value: 'rejected', label: 'Rejected' },
    { value: 'not_started', label: 'Not Started' },
  ],
  'Plan Due Date': ['Past Due'],
};

export default function FilterMenu() {
  const { filteredVerificationStatus, filteredPlanStatus, filteredPastDue, filteredQuestionType } =
    useAppSelector((state) => state.remediationFilterSlice);
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const [tempSelectedFilters, setTempSelectedFilters] = useState({
    questionType: filteredQuestionType || null,
    filteredVerificationStatus: filteredVerificationStatus || null,
    filteredPlanStatus: filteredPlanStatus || null,
    filteredPastDue: filteredPastDue || null,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setTempSelectedFilters({
      questionType: filteredQuestionType || null,
      filteredVerificationStatus: filteredVerificationStatus || null,
      filteredPlanStatus: filteredPlanStatus || null,
      filteredPastDue: filteredPastDue || null,
    });
    setIsModalOpen(!isModalOpen);
  };

  const toggleFilterOption = (category: string, option: string) => {
    setTempSelectedFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
  
      if (category === 'Question Type') {
        newFilters.questionType = prevFilters.questionType === option ? null : (option as QuestionTypes);
      } else if (category === 'Validation Status') {
        const isSelected = prevFilters.filteredVerificationStatus === option;
        newFilters.filteredVerificationStatus = isSelected ? null : (option as VerificationStatus);
  
        // If any Validation Status is selected, ensure Plan Status is "Remediated"
        if (!isSelected) {
          newFilters.filteredPlanStatus = 'Remediated';
  
          // Deselect "Past Due" if Validation Status is selected
          newFilters.filteredPastDue = null;
        }
      } else if (category === 'Plan Status') {
        const isSelected = prevFilters.filteredPlanStatus === option;
        newFilters.filteredPlanStatus = isSelected ? null : (option as PlanStatus);
  
        // If a Plan Status other than "Will Remediate Later" is selected, deselect "Past Due"
        if (newFilters.filteredPlanStatus !== 'Will Remediate Later') {
          newFilters.filteredPastDue = null;
        }
  
        // Clear Validation Status if Plan Status is not "Remediated"
        if (newFilters.filteredPlanStatus !== 'Remediated') {
          newFilters.filteredVerificationStatus = null;
        }
      } else if (category === 'Plan Due Date') {
        if (option === 'Past Due') {
          const isSelected = prevFilters.filteredPastDue === true;
          newFilters.filteredPastDue = isSelected ? null : true;
  
          // Automatically set "Plan Status" to "Will Remediate Later" if "Past Due" is selected
          if (!isSelected) {
            newFilters.filteredPlanStatus = 'Will Remediate Later';
  
            // Deselect "Validation Status" if "Past Due" is selected
            newFilters.filteredVerificationStatus = null;
          }
        }
      }
  
      return newFilters;
    });
  };
  
  const clearAllFilters = () => {
    setTempSelectedFilters({
      questionType: null,
      filteredVerificationStatus: null,
      filteredPlanStatus: null,
      filteredPastDue: null,
    });
    dispatch(clearFilters());
    setIsModalOpen(false);
  };

  const applyFilters = () => {
    dispatch(setFilteredQuestionType(tempSelectedFilters.questionType));
    dispatch(setVerificationStatus(tempSelectedFilters.filteredVerificationStatus));
    dispatch(setPlanStatus(tempSelectedFilters.filteredPlanStatus));
    dispatch(setPastDue(tempSelectedFilters.filteredPastDue));
    setIsModalOpen(false);
  };

  const isOptionSelected = (category: string, option: string) => {
    if (category === 'Question Type') {
      return tempSelectedFilters.questionType === option;
    }
    if (category === 'Validation Status') {
      return tempSelectedFilters.filteredVerificationStatus === option;
    }
    if (category === 'Plan Status') {
      return tempSelectedFilters.filteredPlanStatus === option;
    }
    if (category === 'Plan Due Date') {
      return tempSelectedFilters.filteredPastDue === true;
    }
    return false;
  };

  const appliedFiltersCount = [
    filteredQuestionType,
    filteredVerificationStatus,
    filteredPlanStatus,
    filteredPastDue,
  ].filter(Boolean).length;

  return (
    <Box sx={{ px: 2, py: 1 }}>
      <Badge
        badgeContent={appliedFiltersCount}
        color="primary"
        invisible={appliedFiltersCount === 0}
      >
        <Button variant="outlined" onClick={toggleModal} startIcon={<TuneIcon />}>
          Filters
        </Button>
      </Badge>

      <StandardDialog
        isOpen={isModalOpen}
        handleClose={toggleModal}
        title="Remediation Filters"
        maxWidth="lg"
      >
        <Box sx={{ p: 2 }}>
          <Grid container spacing={3}>
            {Object.entries(filterOptions).map(([category, options]) => (
              <Grid item xs={3} key={category}>
                <Typography
                  variant="h6"
                  sx={{
                    mb: 2,
                    pb: 1,
                    fontWeight: 'semibold',
                    borderBottom: '1px solid lightgray',
                  }}
                >
                  {category.toUpperCase()}
                </Typography>
                <Grid container direction="column" spacing={1}>
                  {options.map((option) => {
                    const { value, label } =
                      typeof option === 'string' ? { value: option, label: option } : option;

                    return (
                      <Grid
                        item
                        key={value}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                        }}
                        onClick={() => toggleFilterOption(category, value)}
                      >
                        <Typography
                          sx={{
                            lineHeight: '30px',
                            fontWeight: isOptionSelected(category, value) ? 500 : 'normal',
                            color: isOptionSelected(category, value)
                              ? theme.palette.text.primary
                              : theme.palette.grey[600] || theme.palette.text.primary,
                          }}
                        >
                          {label}
                        </Typography>
                        {isOptionSelected(category, value) && (
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleFilterOption(category, value);
                            }}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button onClick={clearAllFilters} variant="outlined">
              Clear Filters
            </Button>
            <Button variant="contained" onClick={applyFilters}>
              Apply
            </Button>
          </Box>
        </Box>
      </StandardDialog>
    </Box>
  );
}
