import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Company, DocusignWaiver } from '../../interfaces/users.interface';
import { Invoice } from '../../interfaces/billing.interface';
import { getBillingRecord } from '../../services/billingRecords';

import Layout from '../Layout/Layout';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ListItemIcon from '@mui/material/ListItemIcon';
import DescriptionIcon from '@mui/icons-material/Description';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';

import CompanyUsersAccordion from '../Directory/Profile/Users';
import ServicesAccordion from '../Directory/Services/ServicesAccordion';
import {
  updateDocusignWaiver,
  sendDocusignWaiver,
  resendDocusignWaiver,
  getCompany,
  updateCompanyLogo,
  updateCompanyBoolean, updateCompanyAsPaid,
} from '../../services/company';
import { createWaiver, fetchWaiver } from '../../services/docusignWaivers';
import canAdminCompany from '../../services/adminUtils';
import SitesAccordion from '../Directory/Sites/SitesAccordion/SitesAccordion';
import CompanyApplicationsAccordion from '../Directory/Applications/CompanyApplicationsAccordion/CompanyApplicationsAccordion';
import CompanyCertificationsAccordion from '../Directory/Certifications/CompanyCertificationsAccordion';
import CompanyDocumentsAccordion from '../Directory/Applications/Documents/CompanyDocuments';
import { fetchPublishedQuestionnaire, resetSurveys } from '../../app/store/surveySlice';
import { Questionnaire } from '../../interfaces/questionnaire.interface';
import CompanyForm from '../Companies/Forms/CompanyForm';
import CompanySubsidiariesAccordion from '../Directory/Sibsidiaries/CompanySubsidiariesAccordion';

import { billingFrequencyOptions } from '../../interfaces/billing.interface';
import { createSurvey, fetchSurvey } from '../../app/store/surveySlice';
import { BestPracticeStatus, Survey } from '../../interfaces/survey.interface';
import StandardDialog from '../Modals/StandardDialog';
import FileUploaderComp from '../Forms/FileUpload';
import { fetchAssessmentAuditLog, resetAssessments } from '../../app/store/assessmentSlice';
import PendingUsersAccordion from '../Directory/Profile/PendingUsers';
import { AssessmentSurvey } from '../../interfaces/assessment.interface';
import moment from 'moment';
import { FormControlLabel, FormGroup, Link, Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { fetchAllApplicationTypes } from '../../app/store/serviceCategories';
import { fetchAllCompaniesStore } from '../../app/store/companyList';
import AssessmentsAccordion from '../Directory/Assessments/AssessmentsAccordion';
import { fetchAllWorkflows } from '../../app/store/workflowSlice';
import DownloadReportSelect from '../DownloadReport/DownloadReportSelect';
import { QuestionnaireTypes } from '../../services/questionnaires';

export function CompanyProfile(props: { company: Company }) {
  const navigate = useNavigate();
  const { applicationTypes, fetchingApplicationTypes } = useAppSelector(state => state.serviceCategories);
  const { companies, fetchingCompanies } = useAppSelector(state => state.companyList);
  const { user } = useAppSelector(state => state.user);
  const { results: assessments, assessmentLocalStorageItems } = useAppSelector(state => state.assessments);
  const dispatch = useAppDispatch();
  const {
    surveys,
    survey,
    mpa_best_practice: mpaBestPractice,
    site_baseline: siteBaseline,
    application_baseline: applicationBaseline,
    assessor_onboarding: assessorOnboarding,
    content_owner_onboarding: contentOwnerOnboarding,
    vendor_onboaring: vendorOnboarding,
  } = useAppSelector(state => state.surveys);
  const apptheme = useTheme();
  const [company, setCompany] = useState(props.company);
  const [waiverSent, setWaiverSent] = useState<undefined | string>(undefined);
  const [logoFormOpen, setLogoFormOpen] = useState(false);
  const handleLogoFormOpen = () => setLogoFormOpen(true);
  const handleLogoFormClose = () => setLogoFormOpen(false);
  const [onboardingSurvey, setOnboardingSurvey] = useState<Survey | undefined | boolean>(undefined);
  const legacyAssessmentsUrl = 'https://mpaa.app.box.com/folder/188662031553'; 

  const logoFileChangeHandler = (file?: any) => {
    const actualFile = file ? file[0].file : undefined;
    if (!actualFile && !company.logo) return;
    updateCompanyLogo(company.id!, actualFile ? actualFile : '').then((companyResponse: Company) => {
      setCompany(companyResponse);
      handleLogoFormClose();
    });
  };

  const billingFrequency = useMemo(() =>
    billingFrequencyOptions.find((option) => option.value === company.billingFrequency)?.key,
  [company.billingFrequency]);

  const getOnboardingId = () => {
    if (!company) return -1;
    if (company.type === 'vendor') return vendorOnboarding;
    if (company.type === 'content_owner') return contentOwnerOnboarding;
    if (company.type === 'assessor') return assessorOnboarding;
    return -1;
  };

  useEffect(() => {
    if (!assessments?.length || !assessmentLocalStorageItems) return;

    const assessmentSurveyIds:number[] = [];
    assessments.forEach(ass => {
      (ass.surveys as AssessmentSurvey[]).forEach(assSur => {
        if (!assSur.reportIssueDate && assSur.id) assessmentSurveyIds.push(assSur.id);
      });
    });
    if (!assessmentSurveyIds.length) return;

    const assessmentSurveyFetch = () => {
      assessmentSurveyIds.forEach(id => {
        const assLoc = assessmentLocalStorageItems.find(assLocI => assLocI.assessmentSurvey === id);
        if (!assLoc) return;
        dispatch(fetchAssessmentAuditLog({ assessmentSurvey: id, date: assLoc.date }));
      });
    };

    assessmentSurveyFetch();
    const assessmentActionInterval = setInterval(assessmentSurveyFetch, 60000);

    return (() => {
      clearInterval(assessmentActionInterval);
    });
  }, [assessments]);

  const [spBillingRecEndData, setSpBillingRecEndData] = useState<string>('');
  const [spBillingStatusActive, setSpBillingStatusActive] = useState<boolean | null>(null);

  const fetchCompanyBilingRecords = async (id: string) => {
    try {
      const billingRec = await getBillingRecord(id);
      const { billingInvoices, status } = billingRec[0];
      const recentPaidInvoice = billingInvoices.filter((invoice: Invoice) => invoice.status === 'paid').sort((a, b) => (b?.id || 0) - (a?.id || 0))[0];
      setSpBillingRecEndData(recentPaidInvoice?.endDate.toString() || '');
      setSpBillingStatusActive(status === 'active');
    } catch (error) {
      console.error(error, 'Error fetching billing records');
    }
  };
  
  useEffect(() => {
    if (!mpaBestPractice) dispatch(fetchPublishedQuestionnaire(QuestionnaireTypes.MPA_BEST_PRACTICE));
    if (!siteBaseline) dispatch(fetchPublishedQuestionnaire(QuestionnaireTypes.SITE_BASELINE));
    if (!applicationBaseline) dispatch(fetchPublishedQuestionnaire(QuestionnaireTypes.APPLICATION_BASELINE));
    if (company.type === 'assessor' && !assessorOnboarding) dispatch(fetchPublishedQuestionnaire('assessor_onboarding'));
    if (company.type === 'content_owner' && !contentOwnerOnboarding) dispatch(fetchPublishedQuestionnaire('content_owner_onboarding'));
    if (company.type === 'vendor' && !vendorOnboarding) dispatch(fetchPublishedQuestionnaire('vendor_onboaring'));

    if (company.type !== 'vendor' || user?.type === 'vendor_admin') return;

    const SPLocalStorageJSON = localStorage.getItem('SPLast10');
    const SPLocalStorage = SPLocalStorageJSON === null ? [] : JSON.parse(SPLocalStorageJSON).filter((sp:any) => sp.id != company.id);
    if (SPLocalStorage.length === 10) SPLocalStorage.pop();
    SPLocalStorage.unshift({ id: company.id, name: company.name });
    localStorage.setItem('SPLast10', JSON.stringify(SPLocalStorage));

    if (!applicationTypes.length && !fetchingApplicationTypes) dispatch(fetchAllApplicationTypes());
    if (!companies.length && !fetchingCompanies) dispatch(fetchAllCompaniesStore());
    if (user?.type === 'tpn_admin' && company.billingCustomerId) {
      fetchCompanyBilingRecords(company.billingCustomerId);
    }
  }, []);

  useEffect(() => {
    if (!company ||
      !surveys ||
      (company.type === 'assessor' && !assessorOnboarding) ||
      (company.type === 'content_owner' && !contentOwnerOnboarding) ||
      (company.type === 'vendor' && !vendorOnboarding)
    ) return;
    const onBoardingId = getOnboardingId();
    const onboardingSurveys = surveys.filter((cursur: Survey) => (cursur.company as Company).id === company.id && (cursur.questionnaire as Questionnaire).id === onBoardingId).sort((a, b) => b.id - a.id);
    setOnboardingSurvey(onboardingSurveys && onboardingSurveys.length > 0 ? onboardingSurveys[0] : false);
    return (()=> {
      setOnboardingSurvey(undefined);
    });
  }, [ assessorOnboarding, contentOwnerOnboarding, vendorOnboarding, surveys, survey ]);

  useEffect(() => {
    if (!props.company.id) return;

    dispatch(resetSurveys());
    getCompany(`${props.company.id}`).then((companyResult: Company) => setCompany(companyResult));
    // if (company.type !== 'content_owner') {
    //   dispatch(fetchAllAssessments({
    //     company: company.type === 'vendor' ? props.company.id : undefined,
    //     assessor: company.type === 'assessor' ? props.company.id : undefined,
    //   }));
    // }

    return (()=> {
      dispatch(resetAssessments());
    });
  }, [props.company]);

  const PaperItem = styled(Paper)(({ theme }) => ({
    backgroundColor: '#F8F9FD',
    padding: theme.spacing(2),
    boxShadow: 'none',
    border: 'none',
    height: '100%',
  }));

  useEffect(() => {
    if (!user || !company || company.isPaid) return;

    if (user?.companies.length && user.companies[0].id === company.id && user.companies[0].isPaid) setCompany({ ...company, isPaid: user.companies[0].isPaid });
  }, [user]);

  const markCompanyAsPaid = () => {
    updateCompanyAsPaid(company).then((companyResponse: Company) => setCompany(companyResponse));
  };

  const resetCompany = () => {
    if (!company.id) return;
    getCompany(company.id.toString()).then((companyResponse: Company) => setCompany(companyResponse));
  };

  const sendDocusignWaiverToCompany = () => {
    if (!company.id || !company.primaryContactUser?.email) return;
    sendDocusignWaiver(company).then(() => { resetCompany(); setWaiverSent(moment().format('h:mm')); });
  };

  const resendDocusignWaiverToCompany = () => {
    if (!company.id || !company.primaryContactUser?.email || !company.waiver?.id) return;
    resendDocusignWaiver(company).then(() => { resetCompany(); setWaiverSent(moment().format('h:mm')); });
  };

  const markWaiverComplete = (waiver: DocusignWaiver | undefined) => {
    if (!waiver?.id) {
      createWaiver({
        envelopeCompleted: true,
        recipientCompleted: true,
        waiverCompletedAt: new Date(),
      }).then(waiverResponse => {
        setCompany({ ...company, waiver: waiverResponse });
        updateCompanyBoolean({
          id: company.id,
          waivers: [...(company.waivers || []), waiverResponse.id],
        });
      });
    } else {
      waiver.envelopeCompleted = true;
      waiver.recipientCompleted = true;
      waiver.waiverCompletedAt = waiver.waiverCompletedAt ? waiver.waiverCompletedAt : new Date();
      waiver.userSignWaiverAt = waiver.userSignWaiverAt ? waiver.userSignWaiverAt : new Date();
      updateDocusignWaiver(waiver).then((waiverResponse) => {
        company.waiver = waiverResponse;
        setCompany({ ...company });
        updateCompanyBoolean({
          id: company.id,
          waivers: [...(company.waivers || []), waiverResponse.id],
        });
      });
    }
  };

  const userCanAdminCompany: boolean = canAdminCompany(user, company);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [showEditCompanyDetailModal, setShowEditCompanyDetailModal] = useState<boolean>(false);
  const handleSetPrimaryContact = (updatedCompany: Company) => {
    setCompany(updatedCompany);
  };
  const handleEditCompanyDetail = (updatedCompany: Company) => {
    setCompany(updatedCompany);
    setShowEditCompanyDetailModal(false);
  };
  const linkHandler = useCallback(() => navigate(`/questionnaire/${survey?.id}/`), [navigate, survey]);

  const onboardingComplete = () => {
    let shouldShow = false;
    if (!onboardingSurvey || typeof onboardingSurvey === 'boolean') return shouldShow;

    shouldShow = (onboardingSurvey.bestPracticeStatuses as BestPracticeStatus[])?.length > 0 ? false : true;
    (onboardingSurvey.bestPracticeStatuses as BestPracticeStatus[])?.forEach((bestPracticeStatus) => {
      if (bestPracticeStatus.questionsAnsweredCount !== bestPracticeStatus.visibleQuestionsCount) shouldShow = true;
    });
    return shouldShow;
  };

  useEffect(() => {
    if (!survey) return;
    if ((survey.questionnaire as Questionnaire).id === getOnboardingId() && !onboardingComplete()) linkHandler();
  }, [survey]);

  // fetch all workflows
  useEffect(() => {
    if (company?.id) {
      const key =  company.type === 'assessor' ? 'assessor' : 'service_provider';
      dispatch(fetchAllWorkflows({ [key]: company.id, ordering: '-created_at' }));
    }
  }, [company.id]);

  const launchOnboardingSurvey = () => {
    if (!onboardingSurvey) {
      const surId = getOnboardingId();
      if (!company || !company.id || surId === -1 || !surId) return;
      dispatch(createSurvey({ company: company.id, questionnaire: surId }));
    } else {
      dispatch(fetchSurvey((onboardingSurvey as Survey).id as number));
    }
  };

  useEffect(() => {
    const fetchAndUpdateWaiverStatus = () => {
      if (!company?.waiver?.id) {
        return;
      }
      fetchWaiver(company?.waiver.id).then((waiverResponse) => {
        if (waiverResponse.envelopeCompleted || waiverResponse.recipientCompleted) {
          setCompany(prevCompany => ({
            ...prevCompany,
            waiver: waiverResponse,
          }));
        }
      });
    };
    // Set an interval for continuous polling.
    const waiverPollingInterval = setInterval(fetchAndUpdateWaiverStatus, 30000);

    // Fetch immediately before starting the interval.
    fetchAndUpdateWaiverStatus();

    return () => {
      clearInterval(waiverPollingInterval);
    };
  }, [company?.waiver?.id, company?.waiver?.envelopeCompleted, setCompany]);


  const shouldShowBilling = () => {
    return userCanAdminCompany && company.type !== 'assessor' &&
      ((company?.primaryContactUser && company?.billingContact?.name !== company?.primaryContactUser.firstName + ' ' + company?.primaryContactUser.lastName) ||
      company?.contact?.address !== company?.billingContact?.address ||
      company?.contact?.address2 !== company?.billingContact?.address2 ||
      company?.primaryContactUser?.email !== company?.billingEmailTo ||
      company?.billingEmailCc ||
      company?.contact?.phoneNumber !== company?.billingContact?.phoneNumber ||
      company?.billingStatementName !== company?.name ||
      company?.contact?.country !== company?.billingContact?.country ||
      company?.contact?.state !== company?.billingContact?.state ||
      company?.contact?.city !== company?.billingContact?.city);
  };

  const [isGold, setIsGold] = useState(company.contentOwnerLevel === 2);
  const updateContentOwnerLevel = () => {
    setIsGold(prev => {
      updateCompanyBoolean({ id: company.id!, contentOwnerLevel: prev ? 1 : 2 });
      return !prev;
    });
  };

  const useStyle = makeStyles({
    addressSpanStyle: {
      whiteSpace: 'pre-line',
    },
    linkStyle: {
      cursor: 'pointer',
      color: '#277BD4',
      textDecoration: 'none',
      '&:hover': {
        color: '#135ea7',
        textDecoration: 'underline',
      },
    },
  });

  const [anchorElPopover, setAnchorElPopover] = useState<null | HTMLElement>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorElPopover(null);
  };

  const popOverOpen = Boolean(anchorElPopover);
  const classes = useStyle();

  return (
    <>
      <StandardDialog title={`${company.logo ? 'Update' : 'Add'} Company Logo`} handleClose={handleLogoFormClose} isOpen={logoFormOpen}>
        <Stack spacing={2}>
          <Grid item xs={12} mt={2} mb={2}>
            <FileUploaderComp callback={(file: File) => logoFileChangeHandler(file)} multiple={false} hideFileList={true} />
          </Grid>
        </Stack>
      </StandardDialog>
      <StandardDialog title='Edit Company' handleClose={() => setShowEditCompanyDetailModal(false)} isOpen={showEditCompanyDetailModal}>
        <CompanyForm successCallback={handleEditCompanyDetail} company={company} assignUserToEmployees={false} submitButtonText='Update Company' primaryContactCallback={handleSetPrimaryContact} />
      </StandardDialog>
      {(userCanAdminCompany || user?.type === 'tpn_admin') && 
        <Stack spacing={1}>
          {(onboardingSurvey === false || (onboardingSurvey && onboardingSurvey !== true && onboardingComplete())) && 
            <Alert severity="info" action={
              <Button onClick={ () => launchOnboardingSurvey() } sx={{ whiteSpace: 'nowrap' }}>Launch Questionnaire</Button>
            }>Please Complete the onboarding Questionnaire.</Alert>
          }
          {/* {company.context?.showBillingUpdatePrompt && 
            <Alert severity="info">Your renewal is upcoming, please review your billing information.</Alert>
          } */}
          {!company.waiver?.envelopeCompleted && company?.waiver?.recipientCompleted && (company.type === 'vendor' || user?.type === 'tpn_admin') && <Alert severity="info" action={<>
            { user?.type === 'tpn_admin' &&
                <Button sx={{ whiteSpace: 'nowrap' }} onClick={() => markWaiverComplete(company.waiver)}>Mark as complete</Button>
              }
          </>}>TPN is reviewing your membership agreement.</Alert>}
          { !company.waiver?.recipientCompleted && (company.type === 'vendor' || user?.type === 'tpn_admin') &&
            <Alert severity="warning" action={<>
              {company?.primaryContactUser?.email && !company?.waiver && <Button sx={{ whiteSpace: 'nowrap' }} onClick={() => sendDocusignWaiverToCompany()} color="warning">Send Membership Agreement</Button>}
              {company?.primaryContactUser?.email && company?.waiver && !company?.waiver?.recipientCompleted && <Button sx={{ whiteSpace: 'nowrap' }} onClick={() => resendDocusignWaiverToCompany()} color="warning">Resend Membership Agreement</Button>}
              { user?.type === 'tpn_admin' &&
                <Button sx={{ whiteSpace: 'nowrap' }} onClick={() => markWaiverComplete(company.waiver)} color="warning">Mark as complete</Button>
              }
            </>}>You need to sign your membership agreement. Please note the execution date of the agreement is your membership start date and payment will be due.{!company?.primaryContactUser?.email && ' A company primary contact is required.'}{waiverSent && ` Your Waiver was sent at ${waiverSent}.`}</Alert>
          }
          { !company.isPaid && !company.hasPreviouslyPaid && company.type !== 'assessor' &&
            <Alert severity="error" action={<>
              { user?.type === 'tpn_admin' &&
                <Button sx={{ whiteSpace: 'nowrap' }} onClick={() => markCompanyAsPaid()} color="error">Mark as paid</Button>
              }
            </>}>Your membership fee has not yet been paid. Invoices are distributed early each week after the membership agreement has been fully executed. Please note your profile will not be visible in the TPN+ registry until your membership fee is paid.</Alert>
          }
          { !company.isPaid && company.hasPreviouslyPaid && company.type !== 'assessor' &&
            <Alert severity="error" action={<>
              { user?.type === 'tpn_admin' &&
                <Button sx={{ whiteSpace: 'nowrap' }} onClick={() => markCompanyAsPaid()} color="error">Mark as paid</Button>
              }
            </>}>Your annual membership fee has not been received and we regret to inform you that your TPN+ account will be deactivated. This means that you will still have access to your TPN+ profile and all information, however you will no longer be visible in the TPN+ Company registry and Content Owners will not be able to see your security status until payment is received.</Alert>
          }
        </Stack>
      }
      <Paper sx={{ mb:4, position: 'relative' }}>
        <Grid container spacing={2} padding={2} pt={0} mt={3} sx={{ alignItems: 'stretch' }}>
          <Grid item xs={12} md={shouldShowBilling() ? 3 : 4} padding={2}>
            {company.logo ? <img style={{ maxWidth: '100%', maxHeight: '150px' }} src={company.logo} alt="logo" /> : <></>}
            <Typography variant="h6">{company.name}{(!company.logo && userCanAdminCompany) && <span>&nbsp;<Button size='small' variant='outlined' onClick={handleLogoFormOpen}>add logo</Button></span>}</Typography>
            <Typography variant="body2" sx={{ color: apptheme.palette.customThemeColors?.darkGrey, fontSize: 12 }}>
              {company.akaNames && company.akaNames.length > 0 && <span>AKA: {company.akaNames.join(', ')}</span>}
              {company.fkaNames && company.fkaNames.length > 0 && <span><br/>FKA: {company.fkaNames.join(', ')}</span>}
              {company.dbaNames && company.dbaNames.length > 0 && <span><br/>DBA: {company.dbaNames.join(', ')}</span>}
              </Typography>
            {company.type === 'content_owner' && <Link href={legacyAssessmentsUrl} target='_blank'>View Legacy Assessments</Link>}
            {company.type === 'content_owner' && ['content_owner', 'tpn_admin'].includes(user?.type || '') && <><br/><DownloadReportSelect /></> }
            {company.type === 'assessor' && <Box sx={{ display: 'flex', gap: 2, mt: 1, pt: 1, borderTop: '1px solid rgba(0,0,0,.2)' }}>
              <Typography>Level: {company.assessorLevel?.descriptor}</Typography>
              { (company.canAssessCloud || company.canAssessPrem) && <Typography>Qualifications: {company.canAssessPrem && 'Site'}{ company.canAssessCloud && company.canAssessPrem && ', ' }{company.canAssessCloud && 'Cloud'}</Typography>}
            </Box>}
            {user?.type === 'tpn_admin' && company.type === 'content_owner' &&
              <FormGroup>
                <FormControlLabel control={<Switch checked={isGold} onChange={() => updateContentOwnerLevel()} />} label="Gold Member" />
              </FormGroup>
            }
          </Grid>
          <Grid item xs={12} md={shouldShowBilling() ? 3 : 4}>
            <PaperItem>
              <Typography variant="subtitle2">Address:</Typography>
              {company.contact && <Stack direction='column' spacing={2}>
                <Typography>
                  {company.contact.address}<br/>
                  {company.contact?.address2 && <>{company.contact.address2}<br/></>}
                  {company.contact?.address3 && <>{company.contact.address3}<br/></>}
                  {company.contact.city}, {company.contact.state} {company.contact.zipcode}<br/>
                  {company.contact.country}<br/><br />
                  {company.contact.phoneNumber} <br />
                  {company.domain}
                  {userCanAdminCompany && company.type !== 'assessor' && <Typography mt={2} variant="subtitle2" component="span">Annual Gross Revenue: {company.grossRevenue}</Typography>}
                  {userCanAdminCompany && company.type !== 'assessor' && <Typography variant="subtitle2" component="span">Employee Count: {company.employeeCount}</Typography>}
                </Typography>
              </Stack>}
            </PaperItem>
          </Grid>
          {shouldShowBilling() && <Grid item xs={12} md={3}>
            <PaperItem>
              <Typography variant="subtitle2">Billing Address:</Typography>
              {company.billingContact?.name && <>{company.billingContact.name}<br/></>}
              {company.billingStatementName && <>{company.billingStatementName}<br/></>}
              {company.billingContact?.address && <>{company.billingContact?.address}<br/></>}
              {company.billingContact?.address2 && <>{company.billingContact?.address2}<br/></>}
              {company.billingContact?.address3 && <>{company.billingContact?.address3}<br/></>}
              {
                company.billingContact?.country &&
                company.billingContact?.state &&
                company.billingContact?.city &&
                company.billingContact?.zipcode &&
                <>
                {company.billingContact.city}, {company.billingContact.state} {company.billingContact.zipcode} <br/>
                {company.billingContact.country}
                  <br/>
                </>
              }
              {company.billingContact?.phoneNumber && company.billingContact.phoneNumber}
            </PaperItem>
          </Grid>}
          <Grid item xs={12} md={shouldShowBilling() ? 3 : 4}>
            <div>
              {company.primaryContactUser && <>
                <Typography variant="subtitle2">Primary Contact:</Typography>
                <Stack direction='column' spacing={2}>
                  <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    {company.primaryContactUser?.firstName} {company.primaryContactUser?.lastName}<br/>
                    <Tooltip title={company.primaryContactUser?.email}>
                      <Link href={`mailto:${company.primaryContactUser?.email}`} sx={{ textOverflow: 'ellipsis', margin: 0, overflow: 'hidden', cursor: 'pointer' }}>{company.primaryContactUser?.email}</Link>
                    </Tooltip><br />
                    {company.primaryContactUser?.contact?.phoneNumber}
                  </Typography>
                </Stack>
              </>}
              {userCanAdminCompany && (
                <Typography mt={2} variant="subtitle2">
                  Billing Customer ID: {
                    user?.type === 'tpn_admin'
                      ? (
                        <span 
                          onClick={handleOpenPopover} 
                          className={classes.linkStyle}
                        >
                          {company.billingCustomerId}
                        </span>
                      )
                      : company.billingCustomerId
                  }
                </Typography>
              )}
              <Popover
                id="billing-customer-id-popover"
                open={popOverOpen}
                anchorEl={anchorElPopover}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Box>
                  {spBillingStatusActive
                    ? <Alert severity="success" sx={{ backgroundColor: '#FFF' }}>Active through: {moment(spBillingRecEndData).format('MM/DD/YYYY')}</Alert>
                    : <Alert severity="error" sx={{ backgroundColor: '#FFF' }}>Inactive - Expired:  {moment(spBillingRecEndData).format('MM/DD/YYYY')}</Alert>
                  }
                </Box>
              </Popover>
              { user?.type === 'tpn_admin' && <Typography variant="subtitle2">Billing Frequency: {billingFrequency}</Typography> }
              {userCanAdminCompany && <Typography variant="subtitle2">Billing PO Number: {company.billingPoNumber}</Typography>}
              {userCanAdminCompany && <Typography variant="subtitle2">VAT Number: {company?.vatNumber}</Typography>}
              {company.companyLegalContacts && <>
                  <Typography variant="subtitle2" mt={1}>Legal Contact:</Typography>
                {company.companyLegalContacts[0]?.name && <>{company.companyLegalContacts[0]?.name}<br/></>}
                {company.companyLegalContacts[0]?.email && <>{company.companyLegalContacts[0]?.email}<br/></>}
                {company.companyLegalContacts[0]?.address && <span className={classes.addressSpanStyle}>{company.companyLegalContacts[0]?.address}<br/></span>}
              </>}
            </div>
          </Grid>
          {userCanAdminCompany &&
            <Tooltip title="Company Actions">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ position: 'absolute', top: '5px', right: '5px' }}
                aria-controls={open ? 'company-actions-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <MoreHorizIcon />
              </IconButton>
            </Tooltip>
          }
        </Grid>
      </Paper>
      {user?.type === 'tpn_admin' && <CompanySubsidiariesAccordion company={company} companyCallback={setCompany} />}
      {company.type === 'vendor' &&
        <>
          <ServicesAccordion company={company} companyCallback={setCompany} userCanAdminCompany={userCanAdminCompany} />
          <SitesAccordion company={company} companyCallback={setCompany} userCanAdminCompany={userCanAdminCompany} />
          <CompanyApplicationsAccordion company={company} companyCallback={setCompany} userCanAdminCompany={userCanAdminCompany} />
          <CompanyCertificationsAccordion company={company} companyCallback={setCompany} userCanAdminCompany={userCanAdminCompany}/>
        </>
      }
      {(company.type === 'vendor' || company.type === 'assessor') && user?.type !== 'content_owner' &&
        <AssessmentsAccordion company={company} userCanAdminCompany={userCanAdminCompany} />
      }
      {company.type !== 'content_owner' && <CompanyDocumentsAccordion company={company} companyCallback={setCompany} userCanAdminCompany={userCanAdminCompany}/>}
      {((company.type === 'vendor' && userCanAdminCompany) || user?.type === 'tpn_admin' || (company.type === 'content_owner' && userCanAdminCompany)) && <>
        <CompanyUsersAccordion company={company} companyCallback={setCompany}  />
        {company.pendingEmployees.length > 0 && <PendingUsersAccordion company={company} companyCallback={setCompany} />}
      </>}
      {userCanAdminCompany && <>
        <Menu
          anchorEl={anchorEl}
          id="company-actions-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {onboardingSurvey && <MenuItem onClick={ () => launchOnboardingSurvey() }>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            Launch Onboarding
          </MenuItem>}
            <MenuItem onClick={() => { setShowEditCompanyDetailModal(true); }}>
              <ListItemIcon>
                <BusinessOutlinedIcon />
              </ListItemIcon>
              Edit Company
            </MenuItem>
          {company.logo &&
            <MenuItem onClick={() => { handleLogoFormOpen(); }}>
              <ListItemIcon>
                <ImageOutlinedIcon />
              </ListItemIcon>
              Edit Company Logo
            </MenuItem>
          }
          {company.logo &&
            <MenuItem onClick={() => { logoFileChangeHandler(); }}>
              <ListItemIcon>
                <ImageOutlinedIcon />
              </ListItemIcon>
              Delete Company Logo
            </MenuItem>
          }
        </Menu>
      </>}
    </>
  );
}

export default function VendorProfile() {
  const {
    user,
  } = useAppSelector(state => state.user);
  const [company, setCompany] = useState<Company | undefined>(undefined);
  
  useEffect(() => {
    if (!user || user.companies == undefined) return;
    if (user.companies?.length < 1) return;
    if (typeof user.companies[0].primaryContactUser === 'object') {
      setCompany(user.companies[0]);
      return;
    }
    getCompany(`${user.companies[0].id}`).then((companyResult: Company) => setCompany(companyResult));
  }, []);

  return (
    <Layout pageTitle={`${company ? company.name : 'Company'} Profile`}>
      {company && <CompanyProfile company={company} /> }
    </Layout>
  );
}
