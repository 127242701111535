import axios from 'axios';

import getAuthSession from './auth';
import { Questionnaire, QuestionnaireResultArray } from '../interfaces/questionnaire.interface';
import { Domain } from '../interfaces/domain.interface';

export async function getOnBoardingQuestionnaires(companyType: string) {
  const authSession = await getAuthSession();
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_API}/questionnaires/?type=${companyType}&is_published=true`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as QuestionnaireResultArray;
}
export const QuestionnaireTypes = {
  SITE_BASELINE: 'site_baseline',
  APPLICATION_BASELINE: 'application_baseline',
  MPA_BEST_PRACTICE: 'mpa_best_practice',
};

export const questionnaireTypes = [
  { value: 'assessor_onboarding', label: 'Assessor Onboarding' },
  { value: 'content_owner_onboarding', label: 'Content Owner Onboarding' },
  { value: 'vendor_onboaring', label: 'Vendor Onboarding' },
  { value: 'site_baseline', label: 'Site Baseline' },
  { value: 'application_baseline', label: 'Application Baseline' },
  { value: 'mpa_best_practice', label: 'TPN MPA Best Practices Questionnaire' },
];

export async function promoteQuestionnaire(oldParent: number, newParent: number) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/promote-questionnaire-version/previous-parent/${oldParent}/new-parent/${newParent}/`,
    {},
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}

export async function versionQuestionnaire(questionnaireId: number) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/duplicate-questionnaire/${questionnaireId}/`,
    {},
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}

export async function deleteQuestionnaire(questionnaireSlug: string) {
  const authSession = await getAuthSession();
  const response = await axios.delete(
    `${process.env.REACT_APP_BASE_API}/questionnaires/${questionnaireSlug}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}

export const buildFullQuestionnaireObject = async (questionnaireid: number) => {
  const authSession = await getAuthSession();
  return Promise.all([
    axios.get(
      `${process.env.REACT_APP_BASE_API}/domains/?questionnaire=${questionnaireid}&limit=200`,
      {
        headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
      },
    ),
  ]).then(res => {
    return { domains: res[0].data.results as Domain[] };
  });
};

export const fetchQuestionnaire = async (questionnaireSlug: string) => {
  const authSession = await getAuthSession();
  const response =  await axios.get(`${process.env.REACT_APP_BASE_API}/questionnaires/${questionnaireSlug}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Questionnaire;
};

export const fetchTopics = async (topicSlug: string) => {
  const authSession = await getAuthSession();
  const response = await axios.get(`${process.env.REACT_APP_BASE_API}/domains/${topicSlug}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
};


export const fetchBestPractices = async (bpSlug?: string, queryParams?: {
  [key: string]: string | number;
}) => {
  const authSession = await getAuthSession();

  let url = `${process.env.REACT_APP_BASE_API}/best-practices/${bpSlug || ''}`;

  // Append query parameters if they exist and `bpSlug` is not provided
  if (!bpSlug && queryParams) {
    const queryString = new URLSearchParams(queryParams as Record<string, string>).toString();
    url += queryString ? `?${queryString}&limit=9999` : '';
  }

  const response = await axios.get(
    url,
    {
      headers: {
        'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}`,
      },
    },
  );

  return response.data;
};

export const fetchQuestionnaires = async (questionnaireType: string) => {
  const authSession = await getAuthSession();
  const response = await axios.get(`${process.env.REACT_APP_BASE_API}/report-questionnaires/?type=${questionnaireType}`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return await response.data.results as Questionnaire[];
};