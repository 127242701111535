import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import QuestionnaireAnswer from '../Questionnaires/QuestionnnaireAnswer';
import Layout from '../Layout/Layout';
import { fetchPublishedQuestionnaire, fetchSurvey, resetSurvey, updateBPSonCurSurvey } from '../../app/store/surveySlice';
import { Questionnaire } from '../../interfaces/questionnaire.interface';
import {
  resetQuestionnaireAnswer,
  setCompanyCertifications,
  setReadOnly,
  setBPStatusIds,
  fetchSurveyCompany,
  setQuestionAnswerFromPoll,
} from '../../app/store/questionnaireAnswerSlice';
import { Badge, Company, Version } from '../../interfaces/users.interface';

import { statuses } from '../../services/questionnaireQuestionStatus';
import { fetchSurvey as getSurvey } from '../../services/surveys';
import { updateSurvey } from '../../app/store/surveySlice';
import { QuestionAnswer } from '../../interfaces/questionAnswer.interface';
import { BestPracticeStatus } from '../../interfaces/survey.interface';
import { createMultipleBestPracticeStatuses, updateBestPracticeStatus } from '../../services/bestPracticeStatus';
import { resetAssessment } from '../../app/store/assessmentSlice';
import { updateWorkflow } from '../../app/store/workflowSlice';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { QuestionnaireTypes } from '../../services/questionnaires';
import moment from 'moment';

let surveyUpdate = false;

export default function SurveyExperience() {
  const params = useParams();
  const surveyId:number | undefined = params.surveyId ? parseInt(params.surveyId) : undefined;
  const assessmentId:number | undefined = params.assessmentId ? parseInt(params.assessmentId) : undefined;
  const [questionnaire, setQuestionnaire] = useState<Questionnaire | null>(null);
  const dispatch = useAppDispatch();
  const newLocal = useAppSelector(state => state.surveys);
  const surveyComany = useAppSelector(state => state.questionnaireAnswer.company);
  const {
    survey,
    mpa_best_practice: mpaBestPractice,
  } = newLocal;
  const { assessment } = useAppSelector(state => state.assessments);
  const { user } = useAppSelector(state => state.user);
  const { bpStatus, preview, questionStatus, assessmentStatus, company, bestPractice, certControlsById } = useAppSelector(state => state.questionnaireAnswer);
  const { applicationVersions } = useAppSelector(state => state.serviceCategories);
  const { workflow } = useAppSelector(state => state.workflow);
  const [siteOrApplicationName, setSiteOrApplicationName] = useState<string | undefined>(undefined);
  const [pollingBegun, setPollingBegun] = useState(false);
  const blueBadge = useMemo(() => (workflow?.badges as Badge[])?.find(b => b.title === 'tpn_self_reported'), [workflow]);

  useEffect(() => {
    if (
      assessmentId ||
      !surveyId ||
      !bestPractice ||
      !certControlsById ||
      !Object.keys(questionStatus).length ||
      !Object.keys(bpStatus).length ||
      !bpStatus[Object.keys(bpStatus)[0]].id ||
      pollingBegun ||
      (questionnaire && questionnaire.type !== QuestionnaireTypes.MPA_BEST_PRACTICE)
    ) return;

    setPollingBegun(true);
  }, [surveyId, bestPractice, certControlsById, questionStatus, bpStatus]);

  useEffect(() => {
    if (!pollingBegun) return;
    const surveyPoll = setInterval(() => {
      if (!surveyId) return;
      getSurvey(surveyId).then(pollSurvey => {
        (pollSurvey.questionAnswers as QuestionAnswer[]).forEach(qa => {
          if (!questionStatus || !questionStatus[qa.question] || questionStatus[qa.question].questionAnswer?.id) return;
          dispatch(setQuestionAnswerFromPoll(
            qa.question.toString(), 
            qa,
          ));
        });
        if (pollSurvey.status !== survey?.status &&
          !(survey?.status === 'submittedForAssessment' && pollSurvey.status === 'complete')) dispatch(updateSurvey(pollSurvey));
      });
    }, 10000);

    return () => {
      setPollingBegun(false);
      clearInterval(surveyPoll);
    };
  }, [pollingBegun]);

  useEffect(() => {
    if (!user || !questionnaire) return;
    if (user.type !== 'vendor_admin' && questionnaire.type === QuestionnaireTypes.MPA_BEST_PRACTICE) dispatch(setReadOnly(true));
  }, [user, questionnaire, questionStatus, bpStatus]);

  useEffect(() => {
    if (!params || !surveyId) return;
    if (!survey) dispatch(resetSurvey());
    setQuestionnaire(null);
    dispatch(resetQuestionnaireAnswer({
      company: surveyComany,  
    }));

    if (!survey) {
      dispatch(fetchSurvey(surveyId));
      dispatch(resetAssessment());
    }

    surveyUpdate = false;
    if (!mpaBestPractice) dispatch(fetchPublishedQuestionnaire(QuestionnaireTypes.MPA_BEST_PRACTICE));
    return () => {
      dispatch(resetSurvey());
      dispatch(resetQuestionnaireAnswer());
      dispatch(resetAssessment());
      setQuestionnaire(null);
      surveyUpdate = false;
    };
  }, [params, surveyId]);

  useEffect(() => {
    if (!survey || questionnaire || survey.id !== surveyId) return;
    setQuestionnaire(survey.questionnaire as Questionnaire);
    if (survey.bestPracticeStatuses?.length) surveyUpdate = false;
  }, [survey, surveyId]);

  useEffect(() => {
    if (!survey || company || surveyComany) return;
    const companyID = typeof survey.company === 'number' ? survey.company : survey.company.id;
    if (companyID) dispatch(fetchSurveyCompany(companyID));
  }, [survey]);

  useEffect(() => {
    if (!survey || !company) return;

    if (!company.companyCertifications) return;
    const filteredCompanyCerts = company.companyCertifications.filter(ccert => ccert.company === (survey.company as Company).id && ccert.adminApproved && (!blueBadge || moment(ccert.createdAt).unix() < moment(blueBadge.createdAt).unix()));
    let newCompanyCerts = !survey.application && !survey.site ? filteredCompanyCerts : [];
    if (survey.application && survey.version && applicationVersions.length && company.companyApplications) {
      const companyApplication = company.companyApplications.find(CA => CA.application.id === survey.application);
      if (!companyApplication) return;
      filteredCompanyCerts.forEach(ccert => {
        if (ccert.applicationVersions?.find(c => applicationVersions.find(a => a.id === c && (a.version as Version).value === survey.version))) newCompanyCerts.push(ccert);
      });
      dispatch(setCompanyCertifications(newCompanyCerts));
    }
    if (survey.site) {
      filteredCompanyCerts.forEach(ccert => {
        if (ccert.sites.some(app => app.id === survey.site)) newCompanyCerts.push(ccert);
      });
      dispatch(setCompanyCertifications(newCompanyCerts));
    }

    if (!survey.application && !survey.site) return;
    const site = survey.site;
    const application = survey.application;
    if (site) setSiteOrApplicationName(company.locations?.find(loc => loc.id === site)?.name);
    if (application) {
      const curApp = company.companyApplications?.find(app => app.application.id === application);
      if (!curApp) return setSiteOrApplicationName(undefined);
      setSiteOrApplicationName(`${company.name} ${curApp.application.name} ${survey.version ? survey.version : ''}`);
    }

  }, [company, blueBadge, survey?.id]);

  const updateSurveywithnewBPStatuses = async (status:string, questionAnswers:QuestionAnswer[], bpStatuses: BestPracticeStatus[]) => {
    if (!survey || !bpStatus) return;
    const bestPracticeStatuses = await createMultipleBestPracticeStatuses(bpStatuses);
    dispatch(setBPStatusIds(bestPracticeStatuses));
    const newQuestionAnswers = [ ...survey.questionAnswers, ...questionAnswers ].map(qa => typeof qa === 'number' ? qa : qa.id);
    dispatch(updateSurvey({ ...survey, status, questionAnswers: [...new Set(newQuestionAnswers)], bestPracticeStatuses }));
    if (workflow) await dispatch(updateWorkflow({ id: workflow.id, status }));
  };

  useEffect(() => {
    if (preview ||
      !survey ||
      !Object.keys(bpStatus).length ||
      !Object.keys(questionStatus).length ||
      !company ||
      !user ||
      (user.type !== 'vendor_admin' &&  questionnaire?.type === QuestionnaireTypes.MPA_BEST_PRACTICE) ||
      (assessmentStatus && assessmentStatus !== 'Pre-Assessment')
    ) return;
    let newbpStatus = Object.keys(bpStatus).map(bps => bpStatus[bps]);
    let surveyStatus = 'incomplete';
    let newQA:QuestionAnswer[] = [];
    let questionsAnsweredCount = 0;
    let visibleQuestionsCount = 0;
    Object.keys(questionStatus).forEach(id => {
      if (questionStatus[id].questionAnswer) newQA = newQA.concat((questionStatus[id].questionAnswer as QuestionAnswer).id);
    });
    newQA = [ ...new Set([ ...survey.questionAnswers, ...newQA ].map(qa => typeof qa === 'number' ? qa : qa.id)) ];
    Object.keys(bpStatus).forEach(id => {
      if (!id || !bpStatus[id].id || bpStatus[id].status === 'hide') return;
      if ((!surveyStatus || surveyStatus.length === 0) && bpStatus[id].status !== 'hide') surveyStatus = bpStatus[id].status;
      if (statuses.indexOf(surveyStatus) < statuses.indexOf(bpStatus[id].status) && bpStatus[id].status !== 'hide') surveyStatus = bpStatus[id].status;
      questionsAnsweredCount += bpStatus[id].questionsAnsweredCount;
      visibleQuestionsCount += bpStatus[id].visibleQuestionsCount;
    });

    if (questionsAnsweredCount === visibleQuestionsCount && visibleQuestionsCount !== 0 && ['remediation', 'priority', 'submittedForAssessment', 'submitted'].indexOf(surveyStatus) === -1 && surveyStatus.indexOf('remediation') === -1) surveyStatus = 'complete';
    if (survey.status === 'submittedForAssessment' && ['remediation', 'priority', 'submitted'].indexOf(surveyStatus) === -1 && surveyStatus.indexOf('remediation') === -1) surveyStatus = 'submittedForAssessment';
    if (surveyStatus === 'complete' && assessment) surveyStatus = 'submittedForAssessment';
    if (!survey.bestPracticeStatuses?.length) {
      if (!surveyUpdate) updateSurveywithnewBPStatuses(surveyStatus, newQA, newbpStatus);
      surveyUpdate = true;
      return;
    }
    if (!newbpStatus.length || !newbpStatus[0].id) {
      dispatch(setBPStatusIds(survey.bestPracticeStatuses as BestPracticeStatus[]));
      return;
    }
    if (
      (survey.status !== surveyStatus && surveyStatus !== 'remediation') ||
      (survey.bestPracticeStatuses && survey.bestPracticeStatuses.length !== newbpStatus.length)
    ) {
      dispatch(updateSurvey({ ...survey, status: surveyStatus, bestPracticeStatuses: newbpStatus }));
      if (workflow) dispatch(updateWorkflow({ id: workflow.id, status: surveyStatus }));
    }
  }, [questionStatus, bpStatus]);

  useEffect(() => {
    if (
      !survey ||
      !Object.keys(bpStatus).length ||
      bpStatus[Object.keys(bpStatus)[0]].id
    ) return;
    
    let newbpStatus = Object.keys(bpStatus).map(bps => bpStatus[bps]);
    if (!newbpStatus.length || !newbpStatus[0].id) {
      dispatch(setBPStatusIds(survey.bestPracticeStatuses as BestPracticeStatus[]));
      return;
    }
  }, [bpStatus]);

  useEffect(() => {
    if (!bpStatus || !survey || !survey.bestPracticeStatuses ||
      !user || (user.type !== 'vendor_admin' &&  questionnaire?.type === QuestionnaireTypes.MPA_BEST_PRACTICE) ||
      (assessmentStatus && assessmentStatus !== 'Pre-Assessment')
    ) return;
    (survey.bestPracticeStatuses as BestPracticeStatus[]).forEach(bps => {
      const bpid = typeof bps.bestPractice === 'number' ? bps.bestPractice : bps.bestPractice.id;
      if (!bpid || !bpStatus[bpid] || bpStatus[bpid].id === undefined) return;
      if (
        bps.questionsAnsweredCount !== bpStatus[bpid].questionsAnsweredCount ||
        bps.visibleQuestionsCount !== bpStatus[bpid].visibleQuestionsCount ||
        bps.status !== bpStatus[bpid].status ||
        bps.color !== bpStatus[bpid].color
      ) {
        updateBestPracticeStatus({
          id: bpStatus[bpid].id,
          bestPractice: bpStatus[bpid].bestPractice,
          color: bpStatus[bpid].color,
          questionsAnsweredCount: bpStatus[bpid].questionsAnsweredCount,
          visibleQuestionsCount: bpStatus[bpid].visibleQuestionsCount,
          status: bpStatus[bpid].status,
        });
        dispatch(updateBPSonCurSurvey({
          id: bpStatus[bpid].id,
          bestPractice: bpStatus[bpid].bestPractice,
          color: bpStatus[bpid].color,
          questionsAnsweredCount: bpStatus[bpid].questionsAnsweredCount,
          visibleQuestionsCount: bpStatus[bpid].visibleQuestionsCount,
          status: bpStatus[bpid].status,
        }));
      }
    });
  }, [bpStatus]);

  return (
      <Layout pageTitle={`${questionnaire ? questionnaire.title : ''}${siteOrApplicationName ? ` for ${siteOrApplicationName}` : ''}`} link={survey ? user && (user.type === 'tpn_admin' || user.type === 'content_owner') ? '/registry/' + (survey.company as Company).id : '/profile' : undefined} buttonText='Back to Company Details' buttonIcon={ArrowBackIcon}>
        { questionnaire && <QuestionnaireAnswer questionnaire={questionnaire} /> }
      </Layout>
  );
}