import axios from 'axios';
import { fetchQuestionnaires } from './questionnaires';
import getAuthSession from './auth';
import { ReportOption } from '../interfaces/reports.interface';
import User from '../interfaces/users.interface';
import { QuestionnaireTypes } from './questionnaires';

const convertQuestionnaireTitle = (originTitle: string, titleSuffix: string) => {
  let title = originTitle.replace('Best Practices', '');
  let titlePartial: string = `${title} ${titleSuffix}`;
  if (titlePartial.indexOf('TPN') === -1) titlePartial = `TPN ${titlePartial}`;
  return titlePartial;
};

export default async function fetchReportOptions(user?: User) {
  let keyIndex = 1;  // Starting at 1 to avoid 0 as a key.
  const ReportOptions: ReportOption[] = [
    { reportName: 'TPN Assessments Status', reportUrl: 'tpn-service-provider-assessments-status-report', reportKey: keyIndex++ },
  ];

  const questionnaires = await fetchQuestionnaires(QuestionnaireTypes.MPA_BEST_PRACTICE);
  questionnaires.forEach((questionnaire) => {
    let reportName: string = convertQuestionnaireTitle(questionnaire.title, 'Assessor Findings');
    if (!ReportOptions.find((reportOption) => reportOption.reportName === reportName)) {
      ReportOptions.push(
        {
          reportName: reportName,
          reportUrl: 'assessment-survey-report',
          urlKwargs: { questionnaireId: questionnaire.id },
          reportKey: keyIndex++,
        },
      );
    }
  });
  questionnaires.forEach((questionnaire) => {
    let reportName: string = convertQuestionnaireTitle(questionnaire.title, 'Service Provider Answers');
    if (!ReportOptions.find((reportOption) => reportOption.reportName === reportName)) {
      ReportOptions.push(
        {
          reportName: reportName,
          reportUrl: 'service-provider-bp-answers-report',
          urlKwargs: { questionnaireId: questionnaire.id },
          reportKey: keyIndex++,
        });
    }
  });
  if (user?.type === 'tpn_admin') {
    ReportOptions.push({
      reportName: 'All Companies Report',
      reportUrl: 'all-companies-report',
      reportKey: keyIndex++,
    });
    ReportOptions.push({
      reportName: 'Assessments Rollup Report',
      reportUrl: 'assessments-rollup-report',
      reportKey: keyIndex++,
    });
  }

  questionnaires.forEach((questionnaire) => {
    let reportName: string = '';
    if (questionnaire.title.includes('Best Practices') && questionnaire.title.includes('v5.')) {
      let versionStr: string = questionnaire.title.split('v5.')[1];
      reportName = `TPN Questionnaire v5.${versionStr} Remediation Plans`;
    } else {
      reportName = `TPN Questionnaire ${questionnaire.title} Remediation Plans`;
    }

    if (!ReportOptions.find((reportOption) => reportOption.reportName === reportName)) {
      ReportOptions.push(
        {
          reportName: reportName,
          reportUrl: 'gold-remediation-status-report',
          urlKwargs: { questionnaireId: questionnaire.id },
          reportKey: keyIndex++,
        },
      );
    }
  });

  return ReportOptions;
}

export async function fetchReport(reportOption: ReportOption) {
  const authSession = await getAuthSession();
  let url = '';
  if (reportOption.urlKwargs) {
    if (reportOption.reportUrl === 'assessment-survey-report') url = `${process.env.REACT_APP_BASE_API}/assessment-surveys/questionnaire/${reportOption.urlKwargs?.questionnaireId}/reporting/csv/`;
    if (reportOption.reportUrl === 'service-provider-bp-answers-report') url = `${process.env.REACT_APP_BASE_API}/reports/service-provider-bp-answers-report/${reportOption.urlKwargs?.questionnaireId}/csv/`;
    if (reportOption.reportUrl === 'gold-remediation-status-report') url = `${process.env.REACT_APP_BASE_API}/reports/gold-remediation-status-report/${reportOption.urlKwargs?.questionnaireId}/csv/`;
  } else if (reportOption.reportUrl === 'tpn-service-provider-assessments-status-report') url = `${process.env.REACT_APP_BASE_API}/reports/assessments/csv/`;
  else {
    url = `${process.env.REACT_APP_BASE_API}/reporting/${reportOption.reportUrl}/`;
  }
  const response = await axios.get(
    url,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    });
  return response.data;
}
