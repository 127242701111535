import axios from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import getAuthSession from '../../services/auth';
import { Questionnaire, QuestionnaireResultArray } from '../../interfaces/questionnaire.interface';
import { FieldValues } from 'react-hook-form';
import { Domain } from '../../interfaces/domain.interface';

interface InitialQuestionnairesState {
  count: number;
  next?: string;
  previous?: string;
  questionnaire?: Questionnaire;
  results: Questionnaire[];
  deletedQuestionnaireId?: number;
  error?: any;
}

const initialState = {
  count: 0,
  next: undefined,
  previous: undefined,
  questionnaire: undefined,
  results: [],
  deletedQuestionnaireId: undefined,
  error: undefined,
} as InitialQuestionnairesState;

const baseUrl = process.env.REACT_APP_BASE_API;

interface FetchAllArgs {
  unpublished?: boolean;
  type?: string;
}

export const fetchAll = createAsyncThunk(
  'questionnaires/fetchAll',
  async ({ unpublished, type }: FetchAllArgs) => {
    const authSession = await getAuthSession();
    const appendUnpublished = unpublished ? `?is_published=${unpublished}` : '';
    const appendType = type && appendUnpublished ? `&type=${type}` : type ? `?type=${type}` : '';
    const url = `${baseUrl}/questionnaires/${appendUnpublished}${appendType}`;
    try {
      const response = await axios.get(
        url,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return (await response.data) as QuestionnaireResultArray;
    } catch (error: any) {
      return error;
    }
  },
);

export const fetchQuestionnaire = createAsyncThunk(
  'questionnaires/fetchQuestionnaire',
  async (questionnaireSlug: string, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.get(
        `${baseUrl}/questionnaires/${questionnaireSlug}/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return response.data as Questionnaire;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createQuestionnaire = createAsyncThunk(
  'questionnaires/createQuestionnaire',
  async ({ title, description, type, isPublished }: FieldValues, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.post(
        `${baseUrl}/questionnaires/`, {
          title,
          description,
          type,
          isPublished,
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return (await response.data) as Questionnaire;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteQuestionnaire = createAsyncThunk(
  'questionnaires/deleteQuestionnaire',
  async (questionnaireId: number, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      await axios.delete(
        `${baseUrl}/questionnaires/${questionnaireId}/`,
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return questionnaireId;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateQuestionnaire = createAsyncThunk(
  'questionnaires/updateQuestionnaire',
  async ({ id, title, description, domains, slug, isPublished, type, bestPracticeGuidanceLink, includeInReporting }: FieldValues, { rejectWithValue }) => {
    try {
      const authSession = await getAuthSession();
      const response = await axios.patch(
        `${baseUrl}/questionnaires/${slug}/`, {
          id,
          title,
          description,
          domains: domains.map((domain:Domain) => typeof domain === 'number' ? domain : domain.id),
          isPublished,
          type,
          bestPracticeGuidanceLink,
          includeInReporting,
        },
        {
          headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
        },
      );
      return (await response.data) as Questionnaire;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

const questionnaireSlice = createSlice({
  name: 'questionnaires',
  initialState,
  reducers: {
    resetQuestionnaire: (state) => {
      state.questionnaire = undefined;
      state.error = undefined;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<QuestionnaireResultArray>) => {
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
      state.results = action.payload.results.sort((a, b) => a.title.localeCompare(b.title));
    });
    builder.addCase(createQuestionnaire.fulfilled, (state, action: PayloadAction<Questionnaire>) => {
      state.results = [...state.results, action.payload];
      state.questionnaire = action.payload;
    });
    builder.addCase(createQuestionnaire.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    });
    builder.addCase(deleteQuestionnaire.fulfilled, (state, action: PayloadAction<number>) => {
      state.deletedQuestionnaireId = action.payload;
      state.results = state.results.filter((questionnaire: Questionnaire) => questionnaire.id !== action.payload);
    });
    builder.addCase(updateQuestionnaire.fulfilled, (state, action: PayloadAction<Questionnaire>) => {
      state.results = state.results.filter((questionnaire: Questionnaire) => questionnaire.id !== action.payload.id);
      state.results = [...state.results, action.payload].sort((a, b) => a.id - b.id);
      state.error = null;
      state.questionnaire = action.payload;
    });
    builder.addCase(updateQuestionnaire.rejected, (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    });
    builder.addCase(fetchQuestionnaire.fulfilled, (state, action: PayloadAction<Questionnaire>) => {
      state.questionnaire = action.payload;
    });
  },
});

export const { resetQuestionnaire } = questionnaireSlice.actions;

export default questionnaireSlice.reducer;