import * as React from 'react';

import { DataListContain, DataRow, DataCell } from '../Tables/DataList';
import QuestionnaireListItem from './QuestionnaireListItem';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchAll } from '../../app/store/questionnaireSlice';
import { Questionnaire } from '../../interfaces/questionnaire.interface';
import { useState } from 'react';
import StandardModal from '../Modals/StandardDialog';
import QuestionnaireForm from './QuestionnaireForm';
import { FormLabel, Switch } from '@mui/material';
import { forEach } from 'lodash';

export default function QuestionnaireManagement() {
  const { user } = useAppSelector(state =>  state.user);
  const dispatch = useAppDispatch();
  const questionnaires: Questionnaire[] = useAppSelector(state => state.questionnaires.results);
  const [newOpen, setNewOpen] = useState(false);
  const handleNewOpen = () => setNewOpen(true);
  const handleNewClose = () => setNewOpen(false);
  const [fetchUnpublished, setFetchUnpublished] = useState(false);

  React.useEffect(() => {
    dispatch(fetchAll({ unpublished: true }));
  }, []);

  React.useEffect(() => {
    dispatch(fetchAll({ unpublished: fetchUnpublished ? undefined : true }));
  }, [fetchUnpublished]);

  const refreshList = () => {
    dispatch(fetchAll({ unpublished: fetchUnpublished ? undefined : true }));
  };

  function isNotChildQuestionnaires(potentialChildQuestionnaire: Questionnaire): boolean {
    // Checks whether the questionnaire should be returned.
    // Iterates over the questionnaires array,
    // checking each questionnaire's versions property for this questionnaire.
    let isNotAChild: boolean = true;
    forEach(questionnaires, (q: Questionnaire) => {
      forEach(q.versions, (childQuestionnaire: Questionnaire) => {
        if (childQuestionnaire.id === potentialChildQuestionnaire.id) isNotAChild = false;
      });
    });
    return isNotAChild;
  }

  const canEdit = React.useMemo(() => user?.permissions?.includes('can_edit_questionnaires'), [user]);

  return (
    <>
      <StandardModal title='New Questionnaire' isOpen={newOpen} handleClose={handleNewClose} >
        <QuestionnaireForm handleClose={handleNewClose} refresh={refreshList} />
      </StandardModal>
      <DataListContain title="Questionnaires" buttonTitle={canEdit ? 'Questionnaire' : ''} buttonClick={() => handleNewOpen()}>
        <FormLabel sx={{ position: 'absolute', right: '215px', top: '-55px' }}>Show Unpublished Questionnaires
          <Switch onChange={() => setFetchUnpublished(!fetchUnpublished)} defaultChecked={fetchUnpublished} />
        </FormLabel>
        <DataRow header>
          <DataCell xs={1}>Version</DataCell>
          <DataCell xs={3}>Title</DataCell>
          <DataCell xs={3}>Description</DataCell>
          <DataCell xs={1}>Published</DataCell>
          <DataCell xs={2}>Type</DataCell>
          <DataCell xs={2}>Domains</DataCell>
        </DataRow>
        {questionnaires.filter((potentialChildQuestionnaire: Questionnaire) => isNotChildQuestionnaires(potentialChildQuestionnaire)).map((questionnaire: Questionnaire, idx: number) => <QuestionnaireListItem canEdit={canEdit} key={idx} idx={idx} questionnaire={questionnaire} refresh={refreshList} />) }
      </DataListContain>
    </>
  );
}
