import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AlertSliceState {
  showRemediationSaveAlert: boolean;
}

const initialState: AlertSliceState = {
  showRemediationSaveAlert: false,
};

const alertSlice = createSlice({
  name: 'alertSlice',
  initialState,
  reducers: {
    setShowRemediationSaveAlert: (state, action: PayloadAction<boolean>) => {
      state.showRemediationSaveAlert = action.payload;
    },
  },
});

export const { setShowRemediationSaveAlert } = alertSlice.actions;

export default alertSlice.reducer;
